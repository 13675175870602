import React from 'react';
import { checkFeatureFlag } from '../../api/featureFlags';

export interface Props {
  propName: string; // THE NAME OF THE PROP TO INJECT
  flagName: string; // THE NAME OF THE FEATUREFLAG TO RETRIEVE.
  children: JSX.Element | JSX.Element[];
}

// THIS WRAPPER COMPONENT WILL INJECT INTO IT'S CHILDREN A BOOLEAN PROP (propName),
// THE VALUE RETRIEVED FROM flagName.
// UNTIL FEATUREFLAGS ARE LOADED, IT WILL NOT RENDER THE CHILDREN.
export const FeatureFlagWrapper = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (FeatureFlagWrapper as React.FC).displayName = 'FeatureFlag';
  const { children, flagName, propName } = props;
  const [isFlagEnabled, setIsFlagEnabled] = React.useState<boolean | undefined>(undefined);
  React.useEffect(() => {
    checkFeatureFlag(flagName).then((enabled: boolean) => {
      setIsFlagEnabled(enabled);
    });
  });
  const childrenWithFlagProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { [propName]: isFlagEnabled });
    }
    return child;
  });
  return <React.Fragment>{isFlagEnabled !== undefined && childrenWithFlagProps}</React.Fragment>;
};
