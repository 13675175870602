import { Column } from 'react-table';

export type StickyColumn = Column<any> & { sticky: string };

export const STUDENT_TABLE_DEFAULT_COLUMNS: StickyColumn[] = [
  {
    Header: 'Student Name',
    accessor: 'studentName',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'ID',
    accessor: 'hsStudentId',
    width: 100,
    sticky: 'left',
  },
];

export const STUDENT_TABLE_EXPORT_COLUMNS: StickyColumn[] = [
  {
    Header: 'State Student ID',
    accessor: 'stateStudentId',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'Student ID',
    accessor: 'hsStudentId',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'Date of Birth',
    accessor: 'dateOfBirth',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'Class Year',
    accessor: 'classYear',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'Counselor',
    accessor: 'counselor',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'School Name',
    accessor: 'schoolName',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'School ID',
    accessor: 'schoolId',
    width: 100,
    sticky: 'left',
  },
];

export const STUDENT_TABLE_DISTRICT_COLUMNS: StickyColumn[] = [
  {
    Header: 'Counselor',
    accessor: 'counselor',
    width: 100,
    sticky: 'left',
  },
];

export const STUDENT_TABLE_SCHOOL_COLUMNS: StickyColumn[] = [
  {
    Header: 'Counselor',
    accessor: 'counselor',
    width: 100,
    sticky: 'left',
  },
];
