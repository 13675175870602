const css = `.overview-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.overview-percentage {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  color: #00427c;
}

.overview-details {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  color: #333333;
  margin-top: 0;
  margin-bottom: 30px;
}
.overview-details span {
  display: block;
}

.card-content-inner {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
}

.bar-graph {
  margin-left: 15px;
}

@media screen and (min-width: 992px) {
  .start-card-content-inner {
    flex-direction: row;
    align-items: start;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL092ZXJ2aWV3Iiwic291cmNlcyI6WyJvdmVydmlldy5zY3NzIiwiLi4vLi4vZGVmaW5pdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQSxPQ2JVOzs7QURlWjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EsT0NsQmlCO0VEbUJqQjtFQUNBOztBQUNBO0VBQ0U7OztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0lBQ0U7SUFDQSJ9 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
