import { LoaderItem } from '../components/Loader';
import { ToastrItem } from '../components/Toastr';
import { STATUS, STATUSES } from '../constants';

//convert JSON array to CSV
const arrayToCsv = (headers: object[], data: object[]) => {
  const STATUS_MAP = new Map<STATUS, string>();
  STATUSES.forEach((status) => STATUS_MAP.set(status.key, status.value));

  const csvRows = [];
  const headerValues = headers.map((header: any) => header.Header);
  csvRows.push(headerValues.join(','));

  data.map((row: any) => {
    const rowValues = headers.map((header: any) => {
      const rowType = row?.original ? row.original : row;
      const result =
        rowType['scores'] && rowType['scores'][header.key]
          ? rowType['scores'][header.key]
          : rowType['results'][header.key];
      const statusOrScore = isNaN(result) ? STATUS_MAP.get(result || STATUS.NO_DATA) : result;
      const value = rowType[header.accessor] || statusOrScore;
      const escaped = ('' + value).replace(/"/g, '\\"');

      return `"${escaped}"`; // To escape the comma in a address like string.
    });
    csvRows.push(rowValues.join(','));
  });
  return csvRows.join('\n');
};

//export csv
const exportCSV = (data: BlobPart, fileName: string) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName + '.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadCsv = (
  header: object[],
  rowData: object[],
  fileName: string,
  showLoader: (loading: LoaderItem) => void,
  showToastr: (toastr: ToastrItem) => void
) => {
  try {
    showLoader({ loading: true });
    showToastr({ show: false });
    const csvData = arrayToCsv(header, rowData);
    exportCSV(csvData, fileName);
  } catch (error) {
    showToastr({ show: true, type: 'error' });
  } finally {
    setTimeout(() => {
      showLoader({ loading: false });
      showToastr({ show: true, type: 'success' });
    }, 100);
  }
};
