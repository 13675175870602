export const setLocalStorageValueForKeyMonthDayYear = (key: string) => {
  const date = new Date();
  const monthDayYear = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  localStorage.setItem(key, monthDayYear);
};

export const getLocalStorageValueForKeyMonthDayYear = (key: string) => {
  return localStorage.getItem(key);
};

export const isLocalStorageValueSetForKeyMonthDayYear = (key: string) => {
  const monthDayYearSet = localStorage.getItem(key);
  if (monthDayYearSet) {
    const date = new Date();
    const monthDayYear = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    if (monthDayYear === monthDayYearSet) {
      return true;
    }
  }
  return false;
};
