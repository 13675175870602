/* eslint-disable react/prop-types */
import './App.scss';
import React, { useEffect, useState } from 'react';
import { Page, Props } from './containers/Page';
import { ManagePage } from './containers/ManagePage';
import { APP_DISPLAY_NAME } from './constants';
import { getUserContextProps, useHashRouting, pushState, addPageNavFunction } from './utils';
import { getNavianceHighSchools, getUserDetails, NavianceSchool, SRIUser } from './api';

const props: Props = { appDisplayName: APP_DISPLAY_NAME, ...getUserContextProps() } as Props;
export const UserDetailsContext = React.createContext<SRIUser>({});

// This initializes a fake routing system using the url # (hash) and history
useHashRouting();

const App = (): JSX.Element => {
  (App as React.FC).displayName = 'SRI App Container';
  const [userDetails, setUserDetails] = useState<SRIUser>({});
  const [districtHighschools, setDistrictHighSchools] = useState<NavianceSchool[] | null>(null);

  useEffect(() => {
    getUserDetails().then((data: SRIUser) => {
      setUserDetails(data);
    });
    if (props.isDistrictLevel) {
      getNavianceHighSchools().then((navSchools: NavianceSchool[]) => {
        setDistrictHighSchools(navSchools);
      });
    }
  }, []);

  // TODO:: remove this once APIs, Backend Proxy, and Real Data is ready
  const isDev =
    location.port === '4212' ||
    location.port === '4202' ||
    location.hostname === 'succeed.local.naviance.com' ||
    location.hostname.endsWith('.dev.cclr.naviance.com');
  if (isDev) {
    if (!window.SRI_STAFF_UI_CONTEXT) {
      window.SRI_STAFF_UI_CONTEXT = { props: {} } as Window['SRI_STAFF_UI_CONTEXT'];
    }
    if (!props.isDistrictLevel) {
      // Check for isDistrictLevel
      const cypressIsDistrictLevel =
        new URLSearchParams(location.search).get('isDistrictLevel') || 'false';
      props.isDistrictLevel = JSON.parse(cypressIsDistrictLevel);
      window.SRI_STAFF_UI_CONTEXT.props.isDistrictLevel = !!props.isDistrictLevel;
    }
    if (props.isDistrictLevel || window.SRI_STAFF_UI_CONTEXT.props.isDistrictLevel) {
      // District Overrides happens with this priority
      const didOverride =
        new URLSearchParams(location.search).get('districtId') ||
        window.SRI_STAFF_UI_CONTEXT.DISTRICT_ID ||
        '100001DUS'; // set a default for mock data
      window.SRI_STAFF_UI_CONTEXT.DISTRICT_ID = didOverride;
      props.districtId = didOverride;
    } else {
      // School Overrides happens with this priority
      const hsidOverride =
        new URLSearchParams(location.search).get('schoolId') ||
        window.SRI_STAFF_UI_CONTEXT.HIGHSCHOOL_ID ||
        '100001USPU'; // set a default for mock data
      window.SRI_STAFF_UI_CONTEXT.HIGHSCHOOL_ID = hsidOverride;
      props.schoolId = hsidOverride;
    }

    const overrideState = new URLSearchParams(location.search).get('state');
    if (overrideState) {
      // State Overrides
      window.SRI_STAFF_UI_CONTEXT.state = overrideState;
    }

    window.SRI_STAFF_UI_CONTEXT.mockStudents =
      new URLSearchParams(location.search).get('mockStudents') || 'false';
  }

  // added overview as an alias for main for initial has routing
  type PagesType = 'main' | 'manage' | 'overview';

  const [page, setPage] = useState<PagesType>('main');

  // wrap setPage and use this new function so that hash routing works
  const setHashPage = (page: any, tab: string, push = true) => {
    page = page.toLowerCase() as PagesType;
    if (push && tab) {
      pushState(tab);
    }
    setPage(page);
  };

  // add page navigation functions for onhashchange to use
  addPageNavFunction('overview', setHashPage, 'main', 'overview', false);
  addPageNavFunction('studentdetails', setHashPage, 'main', 'studentdetails', false);
  addPageNavFunction('manage', setHashPage, 'manage', 'manage', true);

  // sync the current page and hash
  const currentHash = window.location.hash.replace('#', '');
  if (!window.location.hash && page === 'main') {
    pushState('overview');
  } else if (currentHash === 'manage' && page !== 'manage') {
    setPage('manage');
  }

  return (
    <UserDetailsContext.Provider value={userDetails}>
      {page === 'main' && (
        <Page {...props} gotoManagePage={() => setHashPage('manage', 'manage', true)} />
      )}
      {page === 'manage' && (
        <ManagePage
          isDistrictLevel={props.isDistrictLevel || false}
          districtHighschools={districtHighschools}
          gotoMainPage={() => setHashPage('main', 'overview', true)}
        />
      )}
    </UserDetailsContext.Provider>
  );
};

export default App;
