const css = `.tabs {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  max-height: 32px;
}

.tab-active {
  color: #0075db;
  border-bottom: 5px solid #0075db !important;
  font-weight: 600;
}

.tab {
  margin: 0 15px 5px 5px;
  top: 10px;
  width: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 6px;
  display: inline-flex;
  text-align: center;
  position: relative;
  border: none;
  background-color: white;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL1RhYnMiLCJzb3VyY2VzIjpbInRhYnMuc2NzcyIsIi4uLy4uL2RlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFLE9DWGE7RURZYjtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSJ9 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
