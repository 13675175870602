import './notice.scss';
import React from 'react';
import search from '../../assets/search-big.svg';
import searchStudent from '../../assets/search-student.svg';
import { DISTRICT_DATA_IMPORT, SCHOOL_DATA_IMPORT } from '../../constants';

export enum NOTICE_TYPE {
  IMPORT_SCHOOL,
  IMPORT_DISTRICT,
  IMPORT_SCHOOL_NO_PERMISSION,
  IMPORT_DISTRICT_NO_PERMISSION,
  NO_STUDENT_SCHOOL,
  NO_STUDENT_DISTRICT,
  PROVISION_SCHOOL,
  PROVISION_DISTRICT,
  SELECT_SCHOOL,
  ACTIVATE_DISTRICT_SCHOOL,
  ACTIVATE_STANDALONE_SCHOOL,
}

export const noticeMessages = new Map<number, string>([
  [
    NOTICE_TYPE.NO_STUDENT_SCHOOL,
    'No students were found matching your filter selections. Try selecting a different counselor or class year.',
  ],
  [
    NOTICE_TYPE.NO_STUDENT_DISTRICT,
    'No students were found matching your filter selections. Try selecting a different school, counselor, or class year.',
  ],
  [
    NOTICE_TYPE.PROVISION_DISTRICT,
    'Student Readiness Indicators has not been activated at any school yet. Choose schools to activate from the Manage page.',
  ],
  [
    NOTICE_TYPE.PROVISION_SCHOOL,
    'Student Readiness Indicators is not activated for your school. Activate from the Manage page.',
  ],
  [
    NOTICE_TYPE.SELECT_SCHOOL,
    'Select a school from the school filter to view specific student details.',
  ],
  [
    NOTICE_TYPE.IMPORT_SCHOOL,
    `There is no student data to display yet. <a href="${SCHOOL_DATA_IMPORT}">Set up an import</a> or enter <br> student progress on the Student Details tab.`,
  ],
  [
    NOTICE_TYPE.IMPORT_DISTRICT,
    `There is no student data to display yet. <a href="${DISTRICT_DATA_IMPORT}">Set up an import</a> or enter <br> student progress on the Student Details tab.`,
  ],
  [
    NOTICE_TYPE.IMPORT_SCHOOL_NO_PERMISSION,
    `There is no student data to display yet. Set up an import or enter <br> student progress on the Student Details tab.`,
  ],
  [
    NOTICE_TYPE.IMPORT_DISTRICT_NO_PERMISSION,
    `There is no student data to display yet. Set up an import or enter <br> student progress on the Student Details tab.`,
  ],
  [
    NOTICE_TYPE.ACTIVATE_DISTRICT_SCHOOL,
    'Student Readiness Indicators is not activated for your school. <br> Contact your district administrator to access this feature.',
  ],
  [
    NOTICE_TYPE.ACTIVATE_STANDALONE_SCHOOL,
    'Student Readiness Indicators is not activated for your school. <br> Contact your site administrator to access this feature.',
  ],
]);

export enum IconType {
  SCHOOL,
  STUDENT,
}

export interface Props {
  noticeType: NOTICE_TYPE;
  icon?: IconType;
}

export const Notice = (props: Props): JSX.Element => {
  (Notice as React.FC).displayName = 'Notice';
  const { noticeType, icon = IconType.SCHOOL } = props;
  const noticeMessage = noticeMessages.get(noticeType);
  const iconType = icon === IconType.SCHOOL ? search : searchStudent;

  return (
    <div className="notice-container">
      <div className="notice-icon-container">
        <div className="notice-icon" dangerouslySetInnerHTML={{ __html: iconType }} />
      </div>
      <div className="notice" dangerouslySetInnerHTML={{ __html: noticeMessage as string }}></div>
    </div>
  );
};
