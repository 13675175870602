import './confirmation.scss';
import React from 'react';

export interface Props {
  title: string;
  confirmationClickHandler: () => void;
  closeConfirmationClickHandler: () => void;
  secondaryButtonText?: string;
  primaryButtonText?: string;
}

export const Confirmation = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const {
    title,
    confirmationClickHandler,
    closeConfirmationClickHandler,
    secondaryButtonText = 'Cancel',
    primaryButtonText = 'Ok',
    children,
  } = props;
  (Confirmation as React.FC).displayName = 'Confirmation';
  return (
    <>
      <div className="confirmation-overlay"></div>
      <div className="confirmation-container">
        <div className="confirmation-title">{title}</div>
        <div className="confirmation-text">{children}</div>
        <div className="confirmation-footer">
          <button className="button-secondary close-button" onClick={closeConfirmationClickHandler}>
            {secondaryButtonText}
          </button>
          <button className="button-primary confirm-button" onClick={confirmationClickHandler}>
            {primaryButtonText}
          </button>
        </div>
      </div>
    </>
  );
};
