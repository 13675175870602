export const getUserContextProps = () => window?.SRI_STAFF_UI_CONTEXT?.props as unknown as any;
export const getUserContextDistrictId = () => window?.SRI_STAFF_UI_CONTEXT?.DISTRICT_ID;
export const getUserContextHighSchoolId = () => window?.SRI_STAFF_UI_CONTEXT?.HIGHSCHOOL_ID;
export const getUserContextHsDistrictId = () => window?.SRI_STAFF_UI_CONTEXT?.HS_DISTRICT_ID;
export const getUserContextToggles = () => window?.SRI_STAFF_UI_CONTEXT?.toggles;
export const getUserContextState = () => window?.SRI_STAFF_UI_CONTEXT?.state || null;
export const getUserContextInstitutionName = () => window?.SRI_STAFF_UI_CONTEXT?.institutionName;
export const getUserContextMockStudents = () => window?.SRI_STAFF_UI_CONTEXT?.mockStudents || null;
export const getUserContextStudentGradeLevelEnabled = () =>
  window?.SRI_STAFF_UI_CONTEXT?.isSriEnabledForStudents !== false;
