import { apiClient, getUserContextProps, getUserContextHsDistrictId } from '../utils';
import {
  DISTRICT_EDIT_PERMISSION,
  DISTRICT_VIEW_PERMISSION,
  EXPORT_DISTRICT_DATA_PERMISSION,
  EXPORT_SCHOOL_DATA_PERMISSION,
  GET_USER_DETAILS,
  SCHOOL_EDIT_PERMISSION,
  SCHOOL_VIEW_PERMISSION,
  IS_STANDALONE_DEV,
  MANAGE_SRI_ACTIVATION_DISTRICT,
  MANAGE_SRI_ACTIVATION_SCHOOL,
  SCHOOL_IMPORT_PERMISSION,
  DISTRICT_IMPORT_PERMISSION,
} from '../constants';

export enum SRIRoles {
  UNRESTRICTED_COUNSELOR = 0,
  RESTRICTED_COUNSELOR = 1,
  DISTRICT = 2,
  SCHOOL = 3,
  SUPER_USER = 4,
}

export interface SRIUser {
  contactId?: number;
  districtId?: string;
  id?: number;
  highschoolId?: string;
  permissions?: string[];
  email?: string;
  earlyAccessPrograms?: string[];
  canViewSRIDashboard?: boolean;
  canEditSRIDashboard?: boolean;
  canExportData?: boolean;
  canManageSRIActivation?: boolean;
  canImportData?: boolean;
  userType?: SRIRoles;
  dismissOverviewImportBanner?: boolean;
}

export const getUserDetails = async (): Promise<SRIUser> => {
  if (IS_STANDALONE_DEV) {
    // bypass SAPI and just return true for both perms
    return {
      canViewSRIDashboard: true,
      canEditSRIDashboard: true,
      canExportData: true,
      canManageSRIActivation: true,
      canImportData: true,
      userType: SRIRoles.UNRESTRICTED_COUNSELOR,
    };
  }

  const user = await apiClient({ url: GET_USER_DETAILS, method: 'GET' });

  const { isDistrictLevel, isDistrictGhostedAsSchoolUser } = getUserContextProps();
  const isDistrictUser = isDistrictLevel || isDistrictGhostedAsSchoolUser;
  const isSchoolInDistrict = getUserContextHsDistrictId() !== undefined;
  const { permissions } = user;
  return {
    ...user,
    userType: user.type,
    canViewSRIDashboard: isDistrictLevel
      ? permissions.includes(DISTRICT_VIEW_PERMISSION)
      : permissions.includes(SCHOOL_VIEW_PERMISSION),
    canEditSRIDashboard:
      isDistrictLevel || isDistrictGhostedAsSchoolUser
        ? permissions.includes(DISTRICT_EDIT_PERMISSION)
        : permissions.includes(SCHOOL_EDIT_PERMISSION),
    canExportData: isDistrictLevel
      ? permissions.includes(EXPORT_DISTRICT_DATA_PERMISSION)
      : permissions.includes(EXPORT_SCHOOL_DATA_PERMISSION),
    canManageSRIActivation: isDistrictUser
      ? isDistrictLevel && permissions.includes(MANAGE_SRI_ACTIVATION_DISTRICT)
      : !isSchoolInDistrict && permissions.includes(MANAGE_SRI_ACTIVATION_SCHOOL),
    canImportData: isDistrictLevel
      ? permissions.includes(DISTRICT_IMPORT_PERMISSION)
      : permissions.includes(SCHOOL_IMPORT_PERMISSION),
  };
};
