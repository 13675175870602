const css = `.sri-main {
  font-family: "Open Sans", sans-serif;
  position: fixed;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.sri-banner {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 27px;
  padding: 15px 10px 10px 15px;
  border-bottom: 1px solid #aaaaaa;
}

.sri-banner-left {
  display: flex;
}

.sri-banner .banner-button {
  color: #0075db;
  border: 1px solid #0075db;
  background-color: transparent;
  height: 32px;
  display: flex;
  padding: 0px 4px 0px 4px;
  margin-left: 25px;
  overflow: hidden;
  border-radius: 4px;
}

.sri-banner .banner-button div {
  display: inline-block;
  overflow: hidden;
}

.sri-banner-left .banner-button .sri-icon-inner {
  position: relative;
  top: 2px;
  left: 2px;
  padding-left: 2px;
  padding-right: 6px;
}

.manage-button-text {
  line-height: 32px;
  height: 32px;
  vertical-align: bottom;
  cursor: pointer;
  padding-right: 4px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.sri-app-name-info {
  display: flex;
}

.banner-dropdowns {
  display: flex;
}

.main-info-modal-content {
  position: fixed;
  display: block;
  width: 532px;
  height: 281px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
}
.main-info-modal-content .main-info-container {
  position: relative;
  display: block;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  width: 100%;
  max-width: 532px;
  background-color: rgba(0, 0, 0, 0.4);
}
.main-info-modal-content .main-info-container .main-info-header {
  border-radius: 4px 4px 0 0;
  position: relative;
  display: block;
  height: 44px;
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #333333;
  background-color: #f1f4f4;
  text-align: center;
  padding-top: 17px;
}
.main-info-modal-content .main-info-container .main-info-body {
  position: relative;
  display: block;
  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #333333;
  background-color: white;
  text-align: left;
  padding: 15px;
}
.main-info-modal-content .main-info-container .main-info-footer {
  position: relative;
  display: flex;
  padding: 13px 10px;
  justify-content: right;
  background-color: white;
  border-radius: 0 0 4px 4px;
}
.main-info-modal-content .main-info-container .main-info-close {
  padding: 3px 12px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
  background-color: #0075db;
  border-radius: 4px;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb250YWluZXJzL1BhZ2UiLCJzb3VyY2VzIjpbInBhZ2Uuc2NzcyIsIi4uLy4uL2RlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRSxPQzNCYTtFRDRCYjtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBLGFDL0VhO0VEZ0ZiO0VBQ0E7RUFDQTtFQUNBLE9DNUZhO0VENkZiLGtCQ3pGTztFRDBGUDtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQSxhQzlGYTtFRCtGYjtFQUNBO0VBQ0E7RUFDQSxPQzNHYTtFRDRHYjtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBLGFDbkhhO0VEb0hiO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLGtCQzFJUztFRDJJVDtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
