import { GET_NAV_FEATURES, IS_LOCAL_DEV, IS_STANDALONE_DEV } from '../constants';
import { apiClient } from '../utils';
export const FeatureFlags = {
  loadRulesByClassYear: 'dev.sri.ui.loadRulesByClassYear',
  v2RulesUpdates: 'dev.sri.ui.v2RulesUpdates',
};

// To ensure that we will only load the featureFlags once
// we are storing the promise from that one load.
// Then any request for the flags gets that same promise.
let enabledFeatureFlags: Promise<string[]> | undefined = undefined;
export const getFeatureFlags = async (): Promise<string[]> => {
  if (IS_STANDALONE_DEV || location.hostname.indexOf('localhost') !== -1) {
    if (window?.TESTING_featureFlags) {
      return window.TESTING_featureFlags;
    }
    return [];
  }
  if (enabledFeatureFlags === undefined) {
    enabledFeatureFlags = loadFeatureFlags();
  }
  return enabledFeatureFlags;
};

const loadFeatureFlags = async (): Promise<string[]> => {
  const response: { [key: string]: boolean } = await apiClient({
    url: GET_NAV_FEATURES,
    method: 'GET',
  });
  return Object.keys(response).filter((key) => response[key]);
};

export const checkFeatureFlag = async (flag: string): Promise<boolean> => {
  return (await getFeatureFlags()).includes(flag);
};
