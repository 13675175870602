import { GET_NAV_HS_DETAILS, GET_NAV_HS_FILTERED, IS_STANDALONE_DEV } from '../constants';
import { apiClient } from '../utils';
import { district_100001DUS } from '../../../lambda/api/mocks';

export interface NavianceSchool {
  nid: string;
  name: string;
  lowestGrade: number;
  highestGrade: number;
  schoolLevel: string;
}

// LOAD A DISTRICT'S SCHOOLS from Naviance.
export const getNavianceHighSchools = async (): Promise<NavianceSchool[]> => {
  if (IS_STANDALONE_DEV) {
    // mock data when running stand alone UI.
    const defaultHSValues = {
      lowestGrade: 7,
      highestGrade: 12,
      schoolLevel: 'high',
    };
    const mockSchools: NavianceSchool[] = district_100001DUS.schools.map((school) => ({
      name: school.name,
      nid: school.id,
      ...defaultHSValues,
    }));
    // bypass Succeed API and just return.
    return [1, 2, 3]
      .map((id) => ({
        name: `Test School ${id}`,
        nid: `id-0${id}`,
        ...defaultHSValues,
      }))
      .concat(mockSchools);
  }

  // load data from succeed api.
  const highschoolsWithDetails: NavianceSchool[] =
    (await apiClient({
      url: GET_NAV_HS_DETAILS,
      method: 'GET',
    })) || [];
  if (!highschoolsWithDetails.filter)
    throw Error(`Unexpected response from ${GET_NAV_HS_DETAILS} API.`);
  // This API will filter the schools, if the user is restricted is district zone.
  const highschoolsFiltered: { nid: string }[] =
    (await apiClient({
      url: GET_NAV_HS_FILTERED,
      method: 'GET',
    })) || [];
  const filteredSchoolIds = highschoolsFiltered.map((h) => h.nid);

  return highschoolsWithDetails
    .filter((h) => filteredSchoolIds.includes(h.nid))
    .filter((h) => h.highestGrade >= 7);
};
