const css = `.student-edit {
  background-color: #f1f4f4;
  border-radius: 6px;
  margin: 13px 13px;
  padding-bottom: 25px;
  font-family: Open Sans, sans-serif;
  color: #333333;
}
.student-edit .tier-card {
  box-shadow: 0 2px 5px -1px #677274;
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}
.student-edit .tier-card .student-edit-child-link {
  color: #0075db;
  cursor: pointer;
}
.student-edit .tier-icon {
  transform: rotate(-90deg);
  margin-top: -12px;
  width: 20px;
}
.student-edit .tier-icon-expanded {
  margin: 0;
  margin-top: -2px;
  width: 20px;
}
.student-edit .tier-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  font-weight: 600;
  align-items: center;
}
.student-edit .tier-0 {
  top: 0;
  width: calc(100% - 32px);
}
.student-edit .tier-0 .tier-indicator {
  cursor: default !important;
}
.student-edit .tier-0 .tier-indicator svg {
  cursor: default !important;
}
.student-edit .tier-1 {
  width: calc(100% - 76px);
  margin: 21px 23px 0 22px;
}
.student-edit .tier-rule {
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
  margin: 10px 0 10px 0px;
  cursor: default !important;
}
.student-edit .tier-rule-left {
  margin: 10px 0 !important;
}
.student-edit .tier-indicator {
  width: auto;
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-right: 5px;
  cursor: pointer;
}
.student-edit .tier-indicator .tier-0 {
  cursor: default !important;
}
.student-edit .tier-indicator .student-edit-breadcrumb {
  white-space: nowrap;
  cursor: pointer;
}
.student-edit .tier-indicator .student-edit-breadcrumb-separator {
  margin: -1px 2px 0 3px;
  font-size: 24px;
  cursor: default !important;
}
.student-edit .tier-indicator .student-edit-breadcrumb-end {
  color: #333333;
  cursor: default !important;
}
.student-edit .tier-status {
  top: 0;
  height: 20px;
  position: relative;
  font-size: 14px;
  width: auto;
  right: 0;
  cursor: default !important;
}
.student-edit .tier-status svg {
  cursor: default !important;
}
.student-edit .tier-status-icon {
  top: -3px;
  height: 20px;
  position: relative;
  font-size: 14px;
  width: auto;
  right: 0;
  cursor: default !important;
}
.student-edit .tier-status-icon svg {
  cursor: default !important;
}
.student-edit .tier-child-status {
  top: 0;
  height: 20px;
  position: relative;
  font-size: 14px;
  width: auto;
  right: 0;
}
.student-edit .tier-child-status svg {
  cursor: default !important;
}
.student-edit .tier-child-description {
  font-size: 14px;
  margin-top: 15px;
}
.student-edit .student-edit-status-selector {
  margin-left: auto;
  padding-left: 10px;
}
.student-edit .tier-child {
  display: inline-flex;
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
  flex-direction: row;
  justify-content: left;
  position: relative;
  margin: 10px 0 10px 0px;
  width: auto;
}

.recursive-student-edit {
  background-color: #f1f4f4;
  border-radius: 6px;
  margin: 13px 13px;
  padding-bottom: 25px;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.recursive-student-edit .tier-card {
  box-shadow: 0 2px 5px -1px #677274;
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}
.recursive-student-edit .no-expand-indent {
  width: 20px;
  visibility: hidden;
}
.recursive-student-edit .tier-icon-button {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recursive-student-edit .tier-icon-button .sri-icon-inner {
  width: 20px;
}
.recursive-student-edit .tier-icon {
  margin-top: -2px;
  width: 20px;
}
.recursive-student-edit .tier-icon svg {
  transform: rotate(-90deg);
}
.recursive-student-edit .tier-icon-expanded {
  width: 20px;
}
.recursive-student-edit .children-indent {
  margin-left: 20px;
}
.recursive-student-edit .tier-1 .child-category-header {
  border-top: #d8d8d8 solid 1px;
  font-weight: 700;
}
.recursive-student-edit .tier-1 .category:first-child {
  margin-top: 20px;
}
.recursive-student-edit .tier-1 .category:last-child .child-category-footer {
  border-bottom: #d8d8d8 solid 1px;
}
.recursive-student-edit .child-category-header {
  padding-top: 20px;
}
.recursive-student-edit .statusIcon .label-name {
  white-space: nowrap;
}
.recursive-student-edit .tier-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: normal;
}
.recursive-student-edit .tier-0 {
  top: 0;
  width: calc(100% - 32px);
}
.recursive-student-edit .tier-0 .tier-indicator {
  cursor: default !important;
}
.recursive-student-edit .tier-0 .tier-indicator svg {
  cursor: default !important;
}
.recursive-student-edit .tier-1 {
  width: calc(100% - 76px);
  margin: 21px 23px 0 0;
}
.recursive-student-edit .indicator-name {
  margin: 0px 10px 5px 0;
  font-size: 14px;
  cursor: pointer;
}
.recursive-student-edit .tier-status {
  top: 0;
  height: 20px;
  position: relative;
  font-size: 14px;
  width: auto;
  right: 0;
  cursor: default !important;
}
.recursive-student-edit .tier-status svg {
  cursor: default !important;
}
.recursive-student-edit .statusIcon {
  margin-left: auto;
}
.recursive-student-edit .student-edit-status-selector {
  margin-left: auto;
  padding-left: 10px;
}
.recursive-student-edit .tier-child {
  display: block;
  font-size: 14px;
  line-height: 21px;
  font-style: normal;
  justify-content: left;
  position: relative;
  margin: 10px 0 10px 0px;
  width: auto;
}
.recursive-student-edit .score-district {
  float: right;
  margin-right: 2px;
}
.recursive-student-edit .score {
  color: #0075db;
  float: right;
  margin-right: 2px;
}
.recursive-student-edit .score-break {
  float: right;
  margin-right: 2px;
}
.recursive-student-edit .no-point {
  cursor: default !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL1N0dWRlbnRFZGl0Iiwic291cmNlcyI6WyJzdHVkZW50LWVkaXQuc2NzcyIsIi4uLy4uL2RlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBLGFDUWlCO0VEUGpCLE9DRmlCOztBRElqQjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFLE9DdkJTO0VEd0JUOztBQUlKO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7QUFFQTtFQUNFOztBQUVBO0VBQ0U7O0FBS047RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBQ0UsT0NuR2E7RURvR2I7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7QUFJSjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUlKO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUtKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQSxhQ3JLaUI7RURzS2pCO0VBQ0E7RUFDQSxPQ2pMaUI7O0FEbUxqQjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQUlKO0VBSUU7RUFDQTs7QUFKQTtFQUNFOztBQU1KO0VBQ0U7O0FBR0Y7RUFDRTs7QUFLQTtFQUNFO0VBQ0E7O0FBRUY7RUFDRTs7QUFFRjtFQUNFOztBQUlKO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUVBO0VBQ0U7O0FBQ0E7RUFDRTs7QUFLTjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUdKO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRSxPQ3BVVztFRHFVWDtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7QUFHRjtFQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
