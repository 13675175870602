import './statusSelector.scss';
import React, { useEffect, useState } from 'react';
import { Icon } from '../Icon';
import { IndicatorStatus } from '../../../../interfaces/api';
import ReactTooltip from 'react-tooltip';

export interface ButtonProps {
  status: IndicatorStatus;
  editable: boolean;
  getSelected: (selected: IndicatorStatus) => void;
  className?: string;
  v2RulesUpdates?: boolean;
}

export interface ButtonGroup {
  name: IndicatorStatus;
  className: string;
  text: string;
}

const buttonGroups: ButtonGroup[] = [
  {
    name: IndicatorStatus.MET,
    className: 'right',
    text: 'Met',
  },
  {
    name: IndicatorStatus.IN_PROGRESS,
    className: 'center',
    text: 'In Progress',
  },
  {
    name: IndicatorStatus.NOT_MET,
    className: 'left',
    text: 'Not Met',
  },
];

export const StatusSelector = (props: ButtonProps): JSX.Element => {
  (StatusSelector as React.FC).displayName = `Drawer Button - ${props.status}`;

  const { status, editable, getSelected, className, v2RulesUpdates } = props;
  const [drawerStatus, setDrawerStatus] = useState<IndicatorStatus>(status);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className={`status-selector ${className}`}>
      <div className="status-selector-inner">
        <div
          className={`status-selector-button-group${
            v2RulesUpdates && !editable ? ' selector-disabled' : ''
          }`}
        >
          {buttonGroups.map(({ className, name, text }) => (
            <button
              disabled={!editable}
              data-cy={name}
              key={name}
              data-tip={v2RulesUpdates && !editable ? '' : text}
              data-for={v2RulesUpdates && !editable ? 'status-tooltip-disabled' : 'status-tooltip'}
              onClick={() => {
                if (editable) {
                  setDrawerStatus(name);
                  getSelected(name);
                }
              }}
              className={`button${
                v2RulesUpdates && !editable ? ' button-disabled' : ''
              } ${className} ${name === drawerStatus ? 'active' : ''}`}
            >
              <Icon
                disabled={v2RulesUpdates ? (editable ? false : true) : undefined}
                name={`${name}${name === drawerStatus ? 'Active' : ''}`}
                color={v2RulesUpdates && !editable && name !== drawerStatus ? 'grey' : undefined}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
