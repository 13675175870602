const css = `.card-banner {
  position: relative;
  left: -10px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  max-height: 48px;
  padding: 1px 10px 5px;
  width: 100%;
  border-bottom: 1px solid #677274;
}

.card-banner-right {
  position: relative;
  display: flex;
  justify-content: right;
}

.indicators {
  position: relative;
  display: inline;
  margin: 3px 5px 0 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL0NhcmRCYW5uZXIiLCJzb3VyY2VzIjpbImNhcmRCYW5uZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
