import './drawer.scss';
import React from 'react';
import FocusTrap from 'focus-trap-react';

type CardContent = JSX.Element | JSX.Element[];

export interface Props {
  children?: CardContent;
  title: string;
  drawerOnRightSide?: boolean;
  secondaryButtonOnRightSide: boolean;
  secondaryButtonText: string;
  primaryButtonText: string;
  isPrimaryButtonDisabled?: boolean;
  saveClickHandler: () => void;
  closeDrawerClickHandler: () => void;
}

export const Drawer = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (Drawer as React.FC).displayName = 'Drawer';

  const {
    children,
    title,
    drawerOnRightSide,
    secondaryButtonOnRightSide,
    secondaryButtonText,
    primaryButtonText,
    isPrimaryButtonDisabled,
    saveClickHandler,
    closeDrawerClickHandler,
  } = props;

  const drawerSide = drawerOnRightSide ? 'right-drawer' : 'left-drawer';
  const showLeftSideSecondaryButton = secondaryButtonOnRightSide ? ' dont-display' : '';
  const showRightSideSecondaryButton = secondaryButtonOnRightSide ? '' : ' dont-display';

  return (
    <>
      <div className="drawer-overlay"></div>
      <FocusTrap
        focusTrapOptions={{
          delayInitialFocus: false,
          // Fallback focus for jest only
          fallbackFocus: () => document.getElementsByClassName('drawer-button')?.[0] as HTMLElement,
        }}
      >
        <div className={`drawer ${drawerSide}`} role="dialog">
          <div className="modal-dialog-base">
            <div className="modal-header">{title}</div>
            <div className="modal-content">{children}</div>
            <div className="modal-action-bar">
              <div className="footer-left-button">
                <div
                  className={`drawer-button button-secondary${showLeftSideSecondaryButton}`}
                  onClick={closeDrawerClickHandler}
                >
                  {secondaryButtonText}
                </div>
              </div>
              <div className="footer-right-buttons">
                <button
                  className={`drawer-button button-secondary${showRightSideSecondaryButton}`}
                  onClick={closeDrawerClickHandler}
                >
                  {secondaryButtonText}
                </button>
                <button
                  className="drawer-button button-primary"
                  disabled={isPrimaryButtonDisabled}
                  onClick={saveClickHandler}
                >
                  {primaryButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </FocusTrap>
    </>
  );
};
