import React, { useEffect, useState } from 'react';
import { MenuProps, MenuItem, Menu } from '../Menu';
import { MenuList } from '../MenuList';

export interface DropdownProps extends Omit<MenuProps, 'children'> {
  list: MenuItem[];
  justifyRight?: boolean;
  opensUp?: boolean;
}

export const Dropdown = (props: DropdownProps): JSX.Element => {
  (Dropdown as React.FC).displayName = 'Dropdown';
  const { list, placeholder, menuItemOnClick, justifyRight, opensUp, selectedItem } = props;

  const [option, setOption] = useState<MenuItem>();

  useEffect(() => {
    if (selectedItem) {
      setOption(selectedItem as MenuItem);
    }
  }, [selectedItem]);

  const selectOption = (item: MenuItem) => {
    setOption(item);
    menuItemOnClick(item);
  };

  return (
    <Menu
      {...props}
      selectedItem={option}
      menuItemOnClick={selectOption}
      placeholder={option?.value || placeholder}
      defaultValue={list[0]?.value}
    >
      <MenuList list={list} justifyRight={justifyRight} opensUp={opensUp} selectedItem={option} />
    </Menu>
  );
};
