import { apiClient, getUserContextState } from '../utils';
import { GLOBAL_DEPTH, GET_STATE_RULES_ENDPOINT } from '../constants';
import { StateRules, StateRulesField } from '../../../interfaces/api';
import { checkFeatureFlag, FeatureFlags } from './featureFlags';

export interface Indicator {
  key: string; // same as id
  value: string; // the Display Name
  // name: string; // same as value
  depth: number; // Used for DropDown item indent
  hidden: boolean; // hide in DropDown list
  Header: string; // same as value
  accessor: string; // same as id
  parentKey: string;
  children: string[] | null;
  description: string;
  editable: boolean;
  isScore: boolean;
  category?: string;
  childFieldCategories?: string[];
}

// this makes DropDownItem[] compatible list allowing for indentation and hiding items beyond GLOBAL_DEPTH
const flatStateRules: { [key: string]: Indicator } = {};
const flatten = (items: StateRulesField[], parentKey: string, flagEnabled: boolean) => {
  items.forEach((item: StateRulesField) => {
    const key: string = item.id;
    const value: string = item.staffName || item.id;
    if (item.fields) {
      const expandable: boolean = flagEnabled
        ? item.fields.some((f) => !f.isScore)
        : item.fields.length > 1;
      const hidden = flagEnabled ? !expandable : item.depth >= GLOBAL_DEPTH && !expandable;

      flatStateRules[key] = {
        key: key,
        value: value,
        depth: item.depth,
        hidden,
        Header: value,
        accessor: `results.${key}`,
        parentKey: parentKey,
        children: [],
        description: item.staffDescription,
        editable: item.editable || false,
        isScore: item.isScore,
        category: item.category,
        childFieldCategories: item.childFieldCategories,
      };

      item.fields?.forEach((field: StateRulesField) => {
        const child = field.id;
        flatStateRules[key].children?.push(child);
        return flatten([field], key, flagEnabled);
      });
    } else {
      flatStateRules[key] = {
        key: key,
        value: value,
        depth: item.depth,
        hidden: true, // item.depth >= GLOBAL_DEPTH,
        Header: value,
        accessor: `results.${key}`,
        parentKey: parentKey,
        description: item.staffDescription,
        children: null,
        editable: item.editable || false,
        isScore: item.isScore,
        category: item.category,
        childFieldCategories: item.childFieldCategories,
      };
    }
  });

  return flatStateRules;
};

// this makes the table compatible specifications to allow mapping of the StudentDetails API response using the field IDs
export const studentTableIndicatorColumns = (indicatorsIn: object, inKey: unknown) => {
  const indicators = Object.values(indicatorsIn);
  const currentIndicatorsColumns: Indicator[] = [];
  indicators.forEach((indicator: Indicator) => {
    if (indicator.key === inKey) {
      currentIndicatorsColumns.push(indicator);
    } else if (indicator.parentKey === inKey) {
      currentIndicatorsColumns.push(indicator);
    }
  });

  return currentIndicatorsColumns;
};

// this requires the state and class year
export const getIndicators = async (classYearParam: any) => {
  const state = getUserContextState() || 'oh';
  const loadRulesByClassYear = await checkFeatureFlag(FeatureFlags.loadRulesByClassYear);
  const v2RulesUpdatesFlag = await checkFeatureFlag(FeatureFlags.v2RulesUpdates);
  const classYear = loadRulesByClassYear ? classYearParam : 2023;
  const url = `${GET_STATE_RULES_ENDPOINT}/${state}/${classYear}`;

  const stateRules: StateRules = await apiClient({
    url: url,
    method: 'GET',
  });

  // Before start processing the response initialize flatStateRules to an empty Object.
  if (loadRulesByClassYear) {
    Object.keys(flatStateRules).forEach((key) => delete flatStateRules[key]);
  }
  const indicatorsMap: { [key: string]: Indicator } = flatten(
    stateRules.fields,
    '',
    v2RulesUpdatesFlag
  );
  const getStateName = stateRules?.stateName;
  const schoolProvidedStudentMessage = stateRules?.schoolProvidedStudentMessage;
  return { indicatorsMap, getStateName, schoolProvidedStudentMessage };
};
