import './modal.scss';
import React from 'react';

export interface Props {
  text?: string;
  isVisible: boolean;
  standardCloseIcon?: boolean;
  toggleVisibility: () => void;
  contentClassName?: string;
}

export const Modal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const {
    text,
    isVisible,
    toggleVisibility,
    children,
    standardCloseIcon = true,
    contentClassName = 'sri-modal-content',
  } = props;

  (Modal as React.FC).displayName = 'Modal';

  const hideCloseClass = standardCloseIcon ? 'sri-modal-close-hidden' : '';

  return (
    <div
      className={`sri-modal sri-modal-visible-${isVisible}`}
      data-cy={`modal-visible-${isVisible}`}
    >
      <div className={contentClassName}>
        <span className={`sri-modal-close ${hideCloseClass}`} onClick={() => toggleVisibility()}>
          &times;
        </span>
        {text ? <p>{text}</p> : children}
      </div>
    </div>
  );
};
