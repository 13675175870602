const css = `.card-wrapper {
  display: block;
  position: relative;
  padding: 1px 1px 10px 0;
  background-color: #e5e5e5;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 0;
}

.card {
  display: block;
  position: relative;
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px -1px #677274;
  flex-grow: 1;
  height: 0;
}

.card-content {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.indicator-name {
  margin: 15px 8px 5px 15px;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}

.sri-please-import-banner {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-left: 3px solid #6bd7db;
  height: auto;
  margin: 15px;
  padding: 13px 18px 13px 18px;
  background-color: #def6f7;
  font-size: 16px;
  line-height: 22px;
  font-family: "Open Sans", sans-serif;
}
.sri-please-import-banner .sri-please-import-banner-text {
  position: relative;
  display: inline-block;
  padding-right: 30px;
}
.sri-please-import-banner .sri-please-import-banner-close {
  display: inline-block;
  background-color: transparent;
  border: none;
}

.manage-button {
  width: 100px;
  margin: 0 auto;
  display: flex;
  color: #0075db;
  border: 1px solid #0075db;
  background-color: transparent;
  height: 37px;
  overflow: hidden;
  justify-content: space-between;
  border-radius: 4px;
}

.manage-button .sri-icon-inner {
  position: relative;
  top: 4px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb250YWluZXJzL0NhcmQiLCJzb3VyY2VzIjpbImNhcmQuc2NzcyIsIi4uLy4uL2RlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQSxrQkNEVztFREVYO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0EsT0MvQmlCOzs7QURrQ25CO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
