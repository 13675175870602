import { apiClient, getUserContextState } from '../utils';
import { GET_DISTRICT_ENDPOINT, GET_SCHOOL_ENDPOINT } from '../constants';
import { Aggregate } from '../../../interfaces/api';
import { Indicator } from '../api';
import { MenuItem } from '../components/Menu';
import { AggregateResults } from '../../../interfaces/api/aggregate';

const mapOverviewData = (aggregate: Aggregate, indicatorKey: string, indicators: Indicator[]) => {
  const results: AggregateResults = aggregate.results;

  const overview = new Map();
  indicators.forEach((indicator: Indicator) => {
    overview.set(indicator.key, results[indicator.key]);
    if (indicator.children) {
      const indicatorArray: object[] = [];
      indicator.children.forEach((child) => {
        indicatorArray.push({ id: child, value: results[`${child}`] });
      });
      overview.set(indicator.key, indicatorArray);
    } else {
      overview.set(indicator.key, results[indicator.key]);
    }
    if (indicator.key === indicatorKey) {
      overview.set(`${indicatorKey}_total`, results[indicatorKey]);
    }
  });
  return overview;
};

export const getOverviewData = async (
  indicatorKey: string,
  indicators: Indicator[],
  classYear: number,
  districtId: string | undefined | null,
  schools?: MenuItem[] | undefined,
  schoolId?: string | undefined | null,
  counselors?: MenuItem[] | undefined
) => {
  const baseUrl: string = districtId ? GET_DISTRICT_ENDPOINT : GET_SCHOOL_ENDPOINT;
  const districtIdPart: string = districtId ? `/${districtId}` : '';
  const schoolsPart: string = districtIdPart !== '' ? '/schools' : '';
  const schoolIdPart: string = schoolId ? `${schoolsPart}/${schoolId}` : '';
  const url = `${baseUrl}${districtIdPart}${schoolIdPart}/results/${classYear}`;

  // TODO:: use querystring for state only for mocks - remove once real API in place
  const state = getUserContextState() || 'oh';

  const schoolOrDistrictAggregate: Aggregate = await apiClient({
    url: `${url}${`?state=${state}`}`, // QS param only for mock data
    method: 'GET',
  });

  const overview = mapOverviewData(schoolOrDistrictAggregate, indicatorKey, indicators);

  const counselorAggregatesData: unknown[] = [];
  const schoolAggregatesData: unknown[] = [];

  // if we are at the school level, then iterate over counselors to get their aggregate responses
  if (schoolId && schoolOrDistrictAggregate?.counselors) {
    const counselorMap = new Map<string, string>();
    counselors?.forEach((counselor) => {
      if (counselor.key && counselor.value) {
        counselorMap.set(counselor.key, counselor.value);
      }
    });
    schoolOrDistrictAggregate.counselors.map((counselor: Aggregate) => {
      const counselorOverview = mapOverviewData(counselor, indicatorKey, indicators);
      const counselorOverviewForSchool = {
        id: counselor.id,
        name: counselorMap.get(counselor?.id || ''),
        indicatorsData: counselorOverview.get(indicatorKey),
        value: counselorOverview.get(`${indicatorKey}_total`),
      };
      counselorAggregatesData.push(counselorOverviewForSchool);
    });
  } else if (schools && schoolOrDistrictAggregate?.schools) {
    // we are at the district level, so iterate over schools to get their aggregate responses
    const schoolsMap = new Map<string, string>();
    schools?.forEach((school) => {
      if (!school.disabled && school.key && school.value) {
        schoolsMap.set(school.key, school.value);
      }
    });
    schoolOrDistrictAggregate.schools.map((school: Aggregate) => {
      const schoolOverview = mapOverviewData(school, indicatorKey, indicators);
      const schoolOverviewForDistrict = {
        id: school.schoolId,
        name: schoolsMap.get(school?.schoolId || ''),
        indicatorsData: schoolOverview.get(indicatorKey),
        value: schoolOverview.get(`${indicatorKey}_total`),
      };
      schoolAggregatesData.push(schoolOverviewForDistrict);
    });
  }

  const childrenAugmentedOverview = {
    totalPercentageMet: overview.get(`${indicatorKey}_total`),
    schoolsData: schoolAggregatesData,
    counselorsData: counselorAggregatesData,
    indicatorsData: overview.get(indicatorKey),
    studentsCount: schoolOrDistrictAggregate?.studentsCount || 0,
  };

  return childrenAugmentedOverview;
};
