import './footer.scss';
import React from 'react';

export const Footer = (): JSX.Element => {
  (Footer as React.FC).displayName = 'Footer';
  return (
    <div>
      <p className="footer-text">
        Copyright 2005-2021 PowerSchool Group LLC and/or its affiliate(s). All rights reserved. All
        trademarks are either owned or licensed by PowerSchool Group and/or its affiliates.
      </p>
    </div>
  );
};
