import React, { useEffect, useState } from 'react';
import { DropdownProps } from '../Dropdown';
import { MenuItem, Menu } from '../Menu';
import { MenuList } from '../MenuList';

export interface MultiSelectDropdownProps extends Omit<DropdownProps, 'menuItemOnClick'> {
  firstItemSelectsAll?: boolean;
  checkboxes?: boolean;
  menuItemsOnClick: MenuItemOnClickFunction<DropdownItemMulti>;
}

export type DropdownItemMulti = {
  values?: MenuItem[];
};

export type MenuItemOnClickFunction<T> = (item: T) => void;

export const MultiSelectDropdown = (props: MultiSelectDropdownProps): JSX.Element => {
  (MultiSelectDropdown as React.FC).displayName = 'MultiSelectDropdown';
  const {
    list,
    placeholder,
    firstItemSelectsAll,
    checkboxes,
    keepDropdownOpen,
    opensUp,
    justifyRight,
    menuItemsOnClick,
  } = props;

  const [selectedItems, setSelectedItems] = useState<MenuItem[]>([]);
  const [placeholderText, setPlaceHolderText] = useState<string>(placeholder || '');

  const selectedClass = !checkboxes ? 'dd-selected dd-multi-selected' : 'dd-selected';

  const joinSelectedItems = (items: MenuItem[]): string =>
    items.reduce((text, item, index) => {
      text += item.value;
      if (index === items.length - 1) return text;
      return text + ', ';
    }, '');

  useEffect(() => {
    const text = selectedItems.length === 0 ? placeholder : joinSelectedItems(selectedItems);
    setPlaceHolderText(text as string);
  }, [selectedItems, placeholder]);

  const isSelected = (item: MenuItem) => {
    return selectedItems.some((selectedItem) => item.key === selectedItem.key);
  };

  const onSelect = (item: MenuItem) => {
    let selected = selectedItems;
    if (isSelected(item) || (firstItemSelectsAll && item.key === list[0].key)) {
      if (firstItemSelectsAll && item.key === list[0].key) {
        selected = isSelected(item) ? [] : list;
      } else {
        selected = selectedItems.filter((selectedItem) => {
          if (firstItemSelectsAll) {
            return selectedItem.key !== item.key && selectedItem.key !== list[0].key;
          }
          return selectedItem.key !== item.key;
        });
      }
    } else {
      selected = [...selectedItems, item];
      if (firstItemSelectsAll && selected.length === list.length - 1) {
        selected = [...selected, list[0]];
      }
    }
    setSelectedItems(selected);
    menuItemsOnClick({ values: selected } as DropdownItemMulti);
  };

  return (
    <Menu
      {...props}
      menuItemOnClick={onSelect}
      placeholder={placeholderText}
      selectedItem={selectedItems}
      keepDropdownOpen={keepDropdownOpen ?? true}
    >
      <MenuList
        list={list}
        justifyRight={justifyRight}
        opensUp={opensUp}
        selectedItem={selectedItems}
        selectedItemClass={selectedClass}
        checkboxes={checkboxes}
      />
    </Menu>
  );
};
