import './menu.scss';
import React, { useEffect, useState, MouseEvent } from 'react';
import { Icon, IconColor } from '../Icon';
import { MenuListProps } from '../MenuList';

export type MenuItem = {
  key: string;
  value: string;
  depth?: number;
  hidden?: boolean;
  disabled?: boolean;
};

export type MenuItemOnClickFunction<T> = (item: T) => void;

export interface MenuProps {
  children: React.ReactElement<MenuListProps>;
  menuItemOnClick: MenuItemOnClickFunction<MenuItem>;
  size?: 'flat' | 'small' | 'large' | 'x-small' | 'full';
  color?: string;
  icon?: string;
  iconColor?: IconColor;
  withDivider?: boolean;
  placeHolderColor?: 'blue' | 'black';
  defaultValue?: string;
  placeholder?: string;
  hideBorder?: boolean;
  selectedItem?: MenuItem | MenuItem[];
  selectedItemClass?: string;
  keepDropdownOpen?: boolean;
  disabled?: boolean;
}

export const Menu = (props: MenuProps): JSX.Element => {
  (Menu as React.FC).displayName = 'Menu';
  const {
    size,
    icon,
    withDivider,
    defaultValue,
    menuItemOnClick,
    placeholder,
    hideBorder,
    selectedItem,
    keepDropdownOpen,
    children,
    disabled,
  } = props;

  let { iconColor, color, placeHolderColor } = props;
  if (disabled) {
    iconColor = color = 'grey';
    placeHolderColor = 'black';
  }

  const [isListOpen, setListOpen] = useState<boolean>(false);

  const sizeClass = size && size !== 'small' ? `dd-${size}` : `dd-small`;
  const sizeHeaderClass = size && size === 'flat' ? `dd-header-${size}` : `dd-header-small`;
  const colorClass = color ? `dd-header-${color}` : '';
  const colorDividerClass = color ? `dd-divider-${color}` : '';
  const defaultDisplayText = placeholder ? placeholder : defaultValue;

  const placeholderColorClass =
    (!selectedItem || (selectedItem as MenuItem[])?.length === 0) &&
    defaultValue !== defaultDisplayText
      ? `dd-placeholder dd-placeholder-${placeHolderColor}`
      : '';

  const disabledPlaceholderClass = disabled ? 'dd-placeholder-disabled' : '';
  let otherHeaderClasses = '';
  if (hideBorder) {
    otherHeaderClasses += 'dd-no-border ';
  }
  const closeList = () => {
    window.removeEventListener('click', closeList);
    setListOpen(false);
  };

  const alignRight = (dd: HTMLElement) => {
    const list: HTMLElement = dd.getElementsByClassName('dd-list')[0] as HTMLElement;

    const viewWidth = document.documentElement.clientWidth;
    const rect = list.getBoundingClientRect();
    const left = Math.floor(rect.left);
    const style = getComputedStyle(list);
    const width = parseInt(style.width);
    const margin = parseInt(style.marginLeft) + parseInt(style.marginRight);
    const padding = parseInt(style.paddingLeft) + parseInt(style.paddingRight);
    const border = parseInt(style.borderLeft) + parseInt(style.borderRight);
    const totalWidth = width + margin + padding + border;
    const overX = 3 + left + totalWidth - viewWidth;
    if (overX > 0) {
      list.style.left = `-${overX}px`;
    }
  };

  const toggleList = (e: MouseEvent) => {
    if (!isListOpen) {
      window.dispatchEvent(new Event('click'));
    }
    setListOpen(!isListOpen);
    e.stopPropagation();
    if (!isListOpen) {
      setTimeout((dd: HTMLElement) => alignRight(dd), 0, e.currentTarget?.parentElement);
    }
  };

  const selectOption = (e: MouseEvent) => {
    e.stopPropagation();
    const target = e.currentTarget as HTMLElement;
    const key = target.getAttribute('data-key') as string;
    const value = target.getAttribute('data-value') as string;
    const menuItem: MenuItem = { key, value };
    menuItemOnClick(menuItem);
    if (!keepDropdownOpen) {
      toggleList(e);
    }
  };

  useEffect(() => {
    if (isListOpen) {
      window.addEventListener('click', closeList);
    } else {
      window.removeEventListener('click', closeList);
    }
  }, [isListOpen]);

  const getHeader = () => {
    const classes = `dd-header-title ${placeholderColorClass} ${disabledPlaceholderClass}`;
    const content = defaultDisplayText;
    return <div className={`${classes}`}>{content}</div>;
  };

  const renderListItems = () => {
    return React.cloneElement(children, { menuItemOnClick: selectOption });
  };

  return (
    <div className={`dd-wrapper ${sizeClass}`}>
      {
        <button
          data-cy="dropdown-button"
          type="button"
          className={`${sizeHeaderClass} ${colorClass} ${otherHeaderClasses}`}
          onClick={(e) => toggleList(e)}
          disabled={disabled}
        >
          {getHeader()}
          {withDivider && <span className={`dd-divider ${colorDividerClass}`} />}
          <div className={`dd-icon-div ${isListOpen ? 'dd-icon-up' : 'dd-icon-down'}`}>
            <Icon name={`${icon || 'triangle'}`} color={`${iconColor || 'grey'}` as IconColor} />
          </div>
        </button>
      }
      {isListOpen && renderListItems()}
    </div>
  );
};
