import './popover.scss';
import React, { useEffect, useState, MouseEvent } from 'react';
import { Icon } from '../Icon';

interface PopoverProps {
  icon: string;
  opensUp?: boolean;
  justifyRight?: boolean; // use this when dropdown is on the left edge of page
}

export const utils: {
  closeList: (() => void) | null;
} = {
  closeList: null,
};

export const Popover = (props: React.PropsWithChildren<PopoverProps>): JSX.Element => {
  (Popover as React.FC).displayName = 'Popover';
  const { icon, children, opensUp, justifyRight } = props;

  const [isListOpen, setPopoverOpen] = useState<boolean>(false);
  const justifyRightClass = justifyRight ? 'po-list-justify-right' : '';
  const listPositionClass = opensUp ? `po-list-above ${justifyRightClass}` : justifyRightClass;

  utils.closeList = () => {
    window.removeEventListener('click', utils.closeList as EventListenerOrEventListenerObject);
    setPopoverOpen(false);
  };

  const alignRight = (dd: HTMLElement) => {
    const list: HTMLElement = dd.getElementsByClassName('po-list')[0] as HTMLElement;
    const viewWidth = document.documentElement.clientWidth;
    const rect = list.getBoundingClientRect();
    const left = Math.floor(rect.left);
    const style = getComputedStyle(list);
    const width = parseInt(style.width);
    const margin = parseInt(style.marginLeft) + parseInt(style.marginRight);
    const padding = parseInt(style.paddingLeft) + parseInt(style.paddingRight);
    const border = parseInt(style.borderLeft) + parseInt(style.borderRight);
    const totalWidth = width + margin + padding + border;
    const overX = 3 + left + totalWidth - viewWidth;
    if (overX > 0) {
      list.style.left = `-${overX}px`;
    }
  };

  const togglePopover = (e: MouseEvent) => {
    if (!isListOpen) {
      window.dispatchEvent(new Event('click'));
    }
    setPopoverOpen(!isListOpen);
    e.stopPropagation();
    if (!isListOpen) {
      setTimeout((po: HTMLElement) => alignRight(po), 0, e.currentTarget?.parentElement);
    }
  };

  useEffect(() => {
    if (isListOpen) {
      window.addEventListener('click', utils.closeList as EventListenerOrEventListenerObject);
    } else {
      window.removeEventListener('click', utils.closeList as EventListenerOrEventListenerObject);
    }
  }, [isListOpen]);

  return (
    <div className={`po-wrapper`}>
      <button
        data-cy="popover-button"
        type="button"
        className={`po-button`}
        onClick={(e) => togglePopover(e)}
      >
        <Icon name={icon} />
      </button>
      {isListOpen && (
        <div role="list" className={'po-list ' + listPositionClass}>
          {children}
        </div>
      )}
    </div>
  );
};
