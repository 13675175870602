import './search.scss';
import React, { useState, ChangeEvent, FocusEvent } from 'react';
import { Icon } from '../Icon';

export interface SearchProps {
  placeholder: string;
  labelName?: string;
  submitQuery: (query: string) => void;
}

export const Search = (props: SearchProps): JSX.Element => {
  (Search as React.FC).displayName = 'Search';

  const { placeholder, labelName, submitQuery } = props;
  const [query, setQuery] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event?.currentTarget?.value);
    submitQuery(event?.currentTarget?.value);
  };

  const handleKeyPress = () => {
    submitQuery(query);
  };

  const handleClick = () => {
    submitQuery(query);
  };

  return (
    <div className="search-div">
      {labelName && <span className="label">{labelName}</span>}
      <input
        type="text"
        value={query}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onFocus={(e: FocusEvent<HTMLInputElement>) => (e.target.placeholder = '')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => (e.target.placeholder = placeholder)}
        placeholder={placeholder}
      />
      <span className="icon" onClick={handleClick}>
        <Icon name="search" />
      </span>
    </div>
  );
};
