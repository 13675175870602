const css = `.drawer {
  position: fixed;
  width: 0;
  right: 0;
  top: 72px;
  bottom: 0px;
  border-radius: 5px;
  margin: 2px 0;
  z-index: 2;
  background-color: rgb(255, 255, 255);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  overflow-x: hidden;
  animation: slide 0.2s forwards;
}

@keyframes slide {
  100% {
    width: 35vw;
  }
}
.left-drawer {
  left: 0;
}

.right-drawer {
  right: 0;
}

.modal-dialog-base {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.modal-header {
  background: #f1f4f4;
  margin: 0 0;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  border-bottom: 1px solid #c4c9ca;
  width: 100%;
  padding: 9px 0;
  height: 50;
}

.modal-content {
  width: 100%;
  height: calc(100% - 67px);
  overflow-y: scroll;
}

.modal-action-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #c4c9ca;
  padding: 5px;
}

.button-text {
  position: static;
  left: 0%;
  right: 0%;
  top: 3.85%;
  bottom: 3.85%;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.drawer-button {
  padding: 3px 12px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  border: 0px;
}
.drawer-button:focus-visible {
  outline: none;
  box-shadow: 0 0 2px 2px #0075db;
}

.drawer-button[disabled] {
  cursor: auto;
  opacity: 0.4;
}

.button-secondary {
  background-color: white;
  border: 1px #0075db solid;
  color: #0075db;
}

.button-primary {
  background-color: #0075db;
  border: 1px #0075db solid;
  color: white;
}

.footer-right-buttons {
  display: flex;
  padding-right: 0.4vw;
}

.footer-left-button {
  padding-left: 0.4vw;
}

.dont-display {
  display: none;
  cursor: default;
}

.drawer-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2a5488;
  opacity: 0.5;
  z-index: 2;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL0RyYXdlciIsInNvdXJjZXMiOlsiZHJhd2VyLnNjc3MiLCIuLi8uLi9kZWZpbml0aW9ucy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUVBOzs7QUFJRjtFQUNFO0lBQ0U7OztBQUlKO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBLGFDM0JpQjtFRDRCakI7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSxhQzNEaUI7RUQ0RGpCO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7O0FBR0o7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
