import { apiClient, getUserContextDistrictId } from '../utils';
import { ALL_SCHOOLS_ITEM, GET_DISTRICT_ENDPOINT } from '../constants';
import { School, Counselor, ProvisionStatus, SchoolProvisionStatus } from '../../../interfaces/api';
import { MenuItem } from '../components/Menu';

export type SchoolsStudentReadinessData = {
  id: string;
  hasStudentReadinessData: boolean;
  classYears: MenuItem[];
};

export const getDistrict = async () => {
  const districtId = getUserContextDistrictId() || '100001DUS';
  const url = `${GET_DISTRICT_ENDPOINT}/${districtId}`;
  const district = await apiClient({ url, method: 'GET' });

  const schools: MenuItem[] = [];
  const counselors = new Map<string, MenuItem[]>();
  const allCounselors: MenuItem = { key: '1', value: 'All Counselors' };
  const unassigned: MenuItem = { key: '0', value: 'Unassigned' };
  const classYearsRaw: number[] = [];

  const response = {
    counselors: counselors,
    classYears: [] as MenuItem[],
    schools: schools as MenuItem[],
    provisionStatus: ProvisionStatus.NOT_REQUESTED,
    hasStudentReadinessData: false,
    hasImportedData: false,
    schoolsStudentReadinessData: [] as SchoolsStudentReadinessData[],
    schoolProvisionStatuses: [] as SchoolProvisionStatus[],
  };

  const districtSchools = district?.schools || [];

  districtSchools.forEach((school: School) => {
    const completed: boolean = school.provisionedStatus === ProvisionStatus.COMPLETED;
    const status: ProvisionStatus = school.provisionedStatus;
    let displayStatus = '';

    if (status === ProvisionStatus.IN_PROGRESS) {
      displayStatus = ' (PENDING)';
    } else if (status === ProvisionStatus.NOT_REQUESTED) {
      displayStatus = ' (NOT ACTIVATED)';
    } else if (status === ProvisionStatus.FAILED) {
      displayStatus = ' (FAILED)';
    }

    response.schoolProvisionStatuses.push({
      schoolId: school.id,
      schoolName: school.name,
      provisionStatus: school.provisionedStatus,
    });

    const newSchool: MenuItem = {
      key: school.id,
      value: `${school.name}${displayStatus}`,
      disabled: !completed,
    };

    // only add counselors and class years for provisioned schoools
    if (completed) {
      response.provisionStatus = ProvisionStatus.COMPLETED; // students are in SRI DB with at least empty data for each student
      if (school.hasStudentReadinessData) {
        // some SRI data has been imported, edited, or added from TestScores
        response.hasStudentReadinessData = school.hasStudentReadinessData;
      }
      if (school.hasImportedData) {
        // an actual import has been done, as opposed to only manual updates
        response.hasImportedData = school.hasImportedData;
      }

      let dropdownCounselorsArray: MenuItem[] = [];
      const counselors = school.counselors || [];
      counselors.forEach((counselor: Counselor) => {
        const newCounselor: MenuItem = {
          key: counselor.id,
          value: counselor.name,
        };
        dropdownCounselorsArray.push(newCounselor);
      });
      dropdownCounselorsArray.sort((a, b) =>
        a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1
      );

      dropdownCounselorsArray = [allCounselors, ...dropdownCounselorsArray, unassigned];

      response.counselors.set(school.id, dropdownCounselorsArray);
      const classYears = school.classYears || [];
      classYears.forEach((classYear: number) => {
        if (!classYearsRaw.includes(classYear)) {
          classYearsRaw.push(classYear);
        }
      });
    } else {
      response.counselors.set(school.id, []);
    }

    response.schools.push(newSchool);
    const schoolClassYears: MenuItem[] = [];
    school.classYears?.forEach((classYear: number) => {
      const newClassYear: MenuItem = {
        key: `${classYear}`,
        value: `Class of ${classYear}`,
      };

      schoolClassYears.push(newClassYear);
    });
    response.schoolsStudentReadinessData.push({
      id: school.id,
      hasStudentReadinessData: school.hasStudentReadinessData,
      classYears: schoolClassYears.sort((a, b) => parseInt(a.key) - parseInt(b.key)),
    });
  });

  classYearsRaw.forEach((classYear: number) => {
    const newClassYear: MenuItem = {
      key: `${classYear}`,
      value: `Class of ${classYear}`,
    };

    response.classYears.push(newClassYear);
  });

  response.schoolsStudentReadinessData.push({
    id: '0',
    hasStudentReadinessData: response.hasStudentReadinessData,
    classYears: response.classYears,
  });
  response.classYears.sort((a, b) => parseInt(a.key) - parseInt(b.key));
  response.schools.sort((a, b) => (a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1));
  response.schools = [
    { key: ALL_SCHOOLS_ITEM.key, value: ALL_SCHOOLS_ITEM.value },
    ...response.schools,
  ];

  return response;
};
