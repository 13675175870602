import React from 'react';
import './managePage.scss';

import {
  useSortBy,
  useTable,
  Row,
  HeaderGroup,
  UseSortByColumnProps,
  TableKeyedProps,
} from 'react-table';
import { Student } from '../../../../interfaces/api';
import { Icon } from '../../components/Icon';
import { LabeledIcon } from '../../components/LabeledIcon';

export interface SchoolTableProps {
  highschools: { name: string; id: string }[];
  selectedSchools: string[];
  activatedCompletedSchoolIds: string[];
  inProgressSchoolIds: string[];
  failedSchoolIds: string[];
  setSelectedSchools: (selected: string[]) => void;
}

interface SchoolRowDetails {
  name: string;
  status: string;
  id: string;
}
const schoolStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  IN_PROGRESS: 'In Progress',
  FAILED: 'Failed',
} as const;

export const SelectSchoolTable = (props: SchoolTableProps): JSX.Element => {
  (SelectSchoolTable as React.FC).displayName = 'SchoolTable';
  const {
    highschools,
    selectedSchools,
    activatedCompletedSchoolIds,
    inProgressSchoolIds,
    failedSchoolIds,
    setSelectedSchools,
  } = props;
  // only the inactive schools can be "selectable" for activation.
  const selectableSchoolIds = highschools
    .map((h) => h.id)
    .filter((id) => !activatedCompletedSchoolIds.includes(id) && !inProgressSchoolIds.includes(id));
  const isAllSelected = selectedSchools.length === selectableSchoolIds.length;
  const isNoneSelected = selectedSchools.length === 0;
  const getRowSchoolId = (row: Row<SchoolRowDetails>) => {
    return row.original.id;
  };
  const getRowSchoolStatus = (row: Row<SchoolRowDetails>) => {
    return row.original.status;
  };

  // ##################################################################
  // ### React-table setup
  // ##################################################################
  const columns = React.useMemo<{ Header: string; accessor: keyof SchoolRowDetails }[]>(
    () => [
      {
        Header: 'School Name',
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: { row: Row<SchoolRowDetails> }) => {
          return (
            <div className="status-cell">
              {row.original.status === schoolStatus.IN_PROGRESS && (
                <LabeledIcon
                  iconName="statusInProgress"
                  label={row.original.status}
                  labelSide="right"
                />
              )}
              {row.original.status === schoolStatus.ACTIVE && (
                <LabeledIcon
                  iconName="statusActive"
                  label={row.original.status}
                  labelSide="right"
                />
              )}
              {row.original.status === schoolStatus.INACTIVE && (
                <LabeledIcon
                  iconName="statusInactive"
                  label={row.original.status}
                  labelSide="right"
                />
              )}
              {row.original.status === schoolStatus.FAILED && (
                <LabeledIcon iconName="failedBang" label={row.original.status} labelSide="right" />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const getSchoolStatusForDisplay = (schoolId: string) => {
    if (failedSchoolIds.includes(schoolId)) {
      return schoolStatus.FAILED;
    } else if (activatedCompletedSchoolIds.includes(schoolId)) {
      return schoolStatus.ACTIVE;
    } else if (inProgressSchoolIds.includes(schoolId)) {
      return schoolStatus.IN_PROGRESS;
    } else {
      return schoolStatus.INACTIVE;
    }
  };

  const data = React.useMemo<SchoolRowDetails[]>(
    () =>
      highschools?.map((h) => ({
        id: h.id,
        name: h.name,
        status: getSchoolStatusForDisplay(h.id),
      })) || [],
    [highschools, activatedCompletedSchoolIds, inProgressSchoolIds]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'name',
          },
        ],
      },
    },
    useSortBy
  );

  // ##################################################################
  // ### Checkbox EVENT HANDLING
  // ##################################################################
  const rowCheckboxClicked = (row: Row<SchoolRowDetails>) => {
    const rowSchoolId = getRowSchoolId(row);
    const rowIndex = selectedSchools.indexOf(rowSchoolId);
    if (rowIndex === -1) {
      setSelectedSchools([...selectedSchools, rowSchoolId]);
    } else {
      setSelectedSchools(selectedSchools.filter((value) => value !== rowSchoolId));
    }
  };

  // SELECT_ALL/SELECT_NONE/indeterminate Checkbox
  const checkboxRef = React.useRef<HTMLInputElement>(null);
  React.useLayoutEffect(() => {
    if (checkboxRef !== null && checkboxRef.current !== null) {
      const checkboxElement = checkboxRef.current;
      checkboxElement.checked = isAllSelected;
      checkboxRef.current.indeterminate = !isAllSelected && !isNoneSelected;
    }
  }, [selectedSchools, highschools, checkboxRef?.current]);
  const allSelectionCheckBoxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && !isAllSelected) {
      setSelectedSchools(selectableSchoolIds);
    }
    if (!event.target.checked && !isNoneSelected) {
      setSelectedSchools([]);
    }
  };

  // ##################################################################
  // ### RETURN RENDERED CONTENT
  // ##################################################################
  return (
    <table {...getTableProps()}>
      <thead>
        {
          // Loop over the header rows. really only expect 1.
          headerGroups.map((headerGroup: HeaderGroup<SchoolRowDetails>) => {
            //This deconstruction of was done to avoid lint error react/jsx-key
            const { key, ...headerGroupProps }: TableKeyedProps = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...headerGroupProps}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((header, columnIndex) => {
                    const { key, ...headerProps } = header.getHeaderProps(
                      (
                        header as unknown as UseSortByColumnProps<SchoolRowDetails>
                      ).getSortByToggleProps()
                    );
                    const isSorted = (header as unknown as UseSortByColumnProps<Student>).isSorted;
                    return (
                      // Apply the header cell props

                      <th
                        key={key}
                        className={`td ${isSorted ? 'header-dark-background' : ''}`}
                        {...headerProps}
                      >
                        <div className="header-layout">
                          <div className="header-left">
                            {columnIndex == 0 && (
                              <input
                                type="checkbox"
                                ref={checkboxRef}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                onChange={allSelectionCheckBoxChanged}
                                checked={isAllSelected}
                              />
                            )}
                            {
                              // Render the header
                              header.render('Header')
                            }
                          </div>
                          {isSorted ? (
                            <span className="sort-arrow">
                              {(header as unknown as UseSortByColumnProps<Student>).isSortedDesc ? (
                                <Icon name="sortDown" size={16} />
                              ) : (
                                <Icon name="sortUp" size={16} />
                              )}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </th>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          // This deconstruction was done to avoid lint error react/jsx-key
          /* react-table is missing this prop-type */
          /* eslint-disable-next-line react/prop-types  */
          const { key, ...rowProps } = row.getRowProps();
          return (
            <tr key={key} {...rowProps}>
              {
                /* react-table is missing this prop-type */
                /* eslint-disable-next-line react/prop-types */
                row.cells.map((cell, columnIndex) => {
                  // Apply the cell props
                  const { key, ...cellProps } = cell.getCellProps();
                  const rowStatus = getRowSchoolStatus(row);
                  const disabled = (
                    [schoolStatus.ACTIVE, schoolStatus.IN_PROGRESS] as string[]
                  ).includes(rowStatus);
                  return (
                    <td className="td" key={key} {...cellProps}>
                      {columnIndex === 0 && (
                        <input
                          type="checkbox"
                          disabled={disabled}
                          onChange={() => rowCheckboxClicked(row)}
                          checked={disabled || selectedSchools.includes(getRowSchoolId(row))}
                        />
                      )}
                      {
                        // Render the cell contents
                        cell.render('Cell')
                      }
                    </td>
                  );
                })
              }
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
