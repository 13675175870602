import './student-edit.scss';

import React from 'react';
import { Indicator } from '../../api';
import { Icon } from '../Icon';
import { StatusSelector } from '../StatusSelector';
import { LabeledIcon } from '../LabeledIcon';
import { STATUS, STATUSES } from '../../constants';
import { StudentResults } from '../../../../interfaces/api/students';
import { IndicatorStatus } from '../../../../interfaces/api';

export interface RecurseIndicatorsProps {
  indicatorsMap: { [key: string]: Indicator };
  studentData: StudentResults;
  isExpanded: (indicatorId: string) => boolean;
  onIndicatorClick: (indicatorId: string) => void;
  updateIndicatorStatus: (indicatorId: string, status: IndicatorStatus) => void;
  printScores: (indicator: Indicator) => JSX.Element;
  v2RulesUpdates?: boolean;
}

// TODO: should this be moved to commons?
// TODO: should we use something besides ENUM STATUS, we are using IndicatorStatus as lookup!!!
const STATUS_MAP = new Map<STATUS, string>();
STATUSES.forEach((status) => STATUS_MAP.set(status.key, status.value));

export const RecurseIndicators = (
  props: React.PropsWithChildren<RecurseIndicatorsProps>
): JSX.Element => {
  (RecurseIndicators as React.FC).displayName = 'StudentEdit';

  const {
    indicatorsMap,
    studentData,
    isExpanded,
    onIndicatorClick,
    updateIndicatorStatus,
    printScores,
    v2RulesUpdates,
  } = props;

  const topLevelIndicator =
    indicatorsMap[Object.keys(indicatorsMap).find((key) => indicatorsMap[key].depth === 0) || ''];

  //checks if the specific indicator has any children
  const doesIndicatorHaveChild = (indicator: Indicator): boolean => {
    return !!indicator?.children?.length;
  };

  const areAllChildrenScores = (indicator: Indicator): boolean => {
    return (
      !!indicator.children && indicator.children.every((child) => indicatorsMap[child]?.isScore)
    );
  };

  const isIndicatorExpandable = (indicator: Indicator) =>
    indicator.depth > 0 && doesIndicatorHaveChild(indicator) && !areAllChildrenScores(indicator);

  const renderStatusIcon = (indicator: Indicator) => {
    const status = studentData[indicator.key] || IndicatorStatus.NO_DATA;
    return (
      <div className="statusIcon">
        {indicator.editable || areAllChildrenScores(indicator) ? (
          <StatusSelector
            getSelected={(status) => updateIndicatorStatus(indicator.key, status)}
            className="student-edit-status-selector"
            status={status}
            editable={
              // disable selector if all children are scores
              !areAllChildrenScores(indicator)
            }
            v2RulesUpdates={v2RulesUpdates}
          />
        ) : (
          <div>
            <LabeledIcon
              className="tier-status"
              iconName={status}
              label={STATUS_MAP.get(status as unknown as STATUS)}
              labelSide="left"
            />
          </div>
        )}
        {areAllChildrenScores(indicator) && <div> {printScores(indicator)} </div>}
      </div>
    );
  };

  const renderExpandIcon = (indicator: Indicator) => {
    const expand = () => onIndicatorClick(indicator.key);
    return (
      <div className={`tier-icon${isExpanded(indicator.key) ? '-expanded' : ''}`}>
        <button className="tier-icon-button" onClick={expand}>
          <Icon name="angle" color="blue" />
        </button>
      </div>
    );
  };

  // This get rendered to preserve the layout and no-expand-indent gets style visibility: hidden.
  // When an empty div was rendered with the desired width, the width was not honored when the
  // indicator description was larger.
  const renderExpandIndention = () => {
    return (
      <div className="no-expand-indent">
        <Icon name="angle" color="blue" />
      </div>
    );
  };

  const renderHeader = (indicator: Indicator) => {
    const isExpandable = isIndicatorExpandable(indicator);
    const expand = () => onIndicatorClick(indicator.key);
    // Tier 0 never expands, and does not indent for expand icon.
    const levelExpands = indicator.depth > 0;
    return (
      <div className="tier-card-header">
        {isExpandable ? renderExpandIcon(indicator) : undefined}
        {!isExpandable && levelExpands && indicator.depth !== 1
          ? renderExpandIndention()
          : undefined}
        <div className={'tier-indicator'}>
          <div
            className={'indicator-name' + (isExpandable ? '' : ' no-point')}
            onClick={isExpandable ? expand : undefined}
          >
            {indicator.value}
          </div>
          {(!isExpandable || isExpanded(indicator.key)) && (
            <span
              className="indicator-description"
              dangerouslySetInnerHTML={{ __html: indicator.description }}
            ></span>
          )}
        </div>

        {renderStatusIcon(indicator)}
      </div>
    );
  };

  const renderChild = (indicator: Indicator): JSX.Element | undefined => {
    if (!indicator) return undefined;
    // tier 2 (depth=1) child will get rendered in tier-card
    const tierClass = `tier-${indicator.depth} ${indicator.depth > 1 ? 'tier-child' : 'tier-card'}`;
    return (
      <div className={tierClass} key={indicator.key} data-test-id={indicator.value}>
        {renderHeader(indicator)}
        {(!isIndicatorExpandable(indicator) || isExpanded(indicator.key)) &&
          renderChildren(indicator)}
      </div>
    );
  };

  const renderChildren = (indicator: Indicator): JSX.Element | undefined => {
    if (!indicator.children) return undefined;
    const indicatorChildren = indicator.children
      .map((child) => indicatorsMap[child])
      .filter((childIndicator) => !childIndicator.isScore);
    if (!indicatorChildren.length) return undefined;
    // If there are childFieldCategories, then the fields will be grouped by category
    const categories: (string | undefined)[] = [];
    if (indicator.childFieldCategories?.length) {
      categories.push(...indicator.childFieldCategories);
    } else {
      // If there are childFieldCategories, just push undefined, because we will iterate over this array.
      categories.push(undefined);
    }
    // At tier 4 and above we don't indent, instead we draw a line.
    // category-headers are used to draw the line.
    const indentChildren = indicator.depth < 2;
    return (
      <div className={indentChildren ? 'children-indent' : undefined}>
        {categories.map((category) => {
          // This controls when we draw lines.
          const renderCategoryBoxes = !!category || !indentChildren;
          // When we don't indent the children, we still want to indent the lines above and below the children
          const indentLinesClass = !indentChildren && indicator.depth !== 0;
          return (
            <div className="category" key={`category_${category}`}>
              {renderCategoryBoxes && (
                <div
                  className={'child-category-header' + (indentLinesClass ? ' children-indent' : '')}
                >
                  {category}
                </div>
              )}
              <div className={renderCategoryBoxes ? 'category-children' : undefined}>
                {indicatorChildren
                  .filter((childIndicator) => !category || category === childIndicator.category)
                  .map(renderChild)}
              </div>
              {renderCategoryBoxes && (
                <div
                  className={'child-category-footer' + (indentLinesClass ? ' children-indent' : '')}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  // COMPONENT's MAIN RENDER.
  return (
    <div>
      {topLevelIndicator && (
        <div className={`tier-card tier-0`} data-test-id={topLevelIndicator.value}>
          {renderHeader(topLevelIndicator)}
        </div>
      )}
      {topLevelIndicator && topLevelIndicator.children && renderChildren(topLevelIndicator)}
    </div>
  );
};
