import './menulist.scss';
import React, { MouseEvent } from 'react';
import { MenuItem } from '../Menu';
import { Icon } from '../Icon';

export type MenuListItemOnClickFunction = (e: MouseEvent) => void;

export interface MenuListProps {
  list: MenuItem[];
  menuItemOnClick?: MenuListItemOnClickFunction;
  selectedItem?: MenuItem | MenuItem[];
  opensUp?: boolean;
  checkboxes?: boolean;
  selectedItemClass?: string;
  justifyRight?: boolean; // use this when menu is on the left edge of page
  keepListOpen?: boolean;
  itemClassName?: string;
  staticTextHeader?: string;
}

export const MenuList = (props: MenuListProps): JSX.Element => {
  (MenuList as React.FC).displayName = 'Menu';
  const {
    list,
    menuItemOnClick,
    opensUp,
    justifyRight,
    selectedItem,
    selectedItemClass,
    checkboxes,
    itemClassName,
    staticTextHeader,
  } = props;

  const justifyRightClass = justifyRight ? 'dd-list-justify-right' : '';
  const listPositionClass = opensUp ? `dd-list-above ${justifyRightClass}` : justifyRightClass;

  const isSelected = (item: MenuItem): boolean => {
    if (Array.isArray(selectedItem)) {
      const selected = selectedItem.some((menuItem) => menuItem.key === item.key);
      return selected;
    } else {
      return item.key === selectedItem?.key;
    }
  };

  return (
    <div role="list" className={'dd-list ' + listPositionClass}>
      {staticTextHeader && <div className="dd-list-text-static">{staticTextHeader}</div>}
      {list.map((item: MenuItem) => (
        <React.Fragment key={`${item.key}-fragment`}>
          {!item.hidden && (
            <button
              type="button"
              disabled={item.disabled}
              className={`dd-list-item ${item.disabled ? 'dd-list-item-disabled' : ''} ${
                isSelected(item) ? selectedItemClass : ''
              } ${itemClassName || ''} `}
              key={`${item.key}`}
              data-key={`${item.key}`}
              data-value={`${item.value}`}
              onClick={menuItemOnClick}
            >
              {checkboxes && (
                <div className="dd-checkbox">
                  <div className="dd-checked">
                    {isSelected(item) && <Icon name="checked" color="blue" />}
                  </div>
                </div>
              )}
              {item.depth
                ? Array(item.depth * 2)
                    .fill(1)
                    .map((e, i) => <span key={`${item.key}-${i}-indent`}>&nbsp;</span>)
                : ''}
              {item.value}
            </button>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
