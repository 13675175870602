import './cardBanner.scss';
import { Tabs } from '../Tabs';
import { Dropdown } from '../Dropdown';
import React, { Dispatch, SetStateAction } from 'react';
import { MenuItem } from '../Menu';

export interface Props {
  indicators: MenuItem[];
  tabs: string[];
  setIndicator: Dispatch<SetStateAction<MenuItem>>;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  defaultTab?: string;
}

export const CardBanner = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (CardBanner as React.FC).displayName = 'CardBanner';
  const { indicators, tabs, setIndicator, setSelectedTab, defaultTab } = props;

  return (
    <div className="card-banner">
      <Tabs tabs={tabs} setSelectedTab={setSelectedTab} defaultTab={defaultTab} />
      <div className="card-banner-right">
        <div className="indicators">Indicators</div>
        <div data-cy="indicatorSelection">
          <Dropdown
            withDivider
            size="large"
            color="blue"
            iconColor="blue"
            placeHolderColor="blue"
            list={indicators}
            menuItemOnClick={setIndicator}
            justifyRight
          />
        </div>
      </div>
    </div>
  );
};
