import './tableWithFixedColumn.scss';
import React, { MouseEventHandler, useContext } from 'react';
import {
  useTable,
  useBlockLayout,
  useSortBy,
  useFilters,
  Row,
  HeaderGroup,
  UseSortByColumnProps,
  UseFiltersColumnProps,
  Cell,
  usePagination,
  FilterProps,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Dropdown, DropdownProps } from '../../components/Dropdown';
import { MenuItem } from '../../components/Menu';
import { DropdownItemMulti, MultiSelectDropdown } from '../../components/MultiSelectDropDown';
import { svgs, Icon, IconColor } from '../../components/Icon';
import { Notice, NOTICE_TYPE } from '../../components/Notice';
import { STATUS, STATUSES, StickyColumn } from '../../constants';
import { getUserContextProps } from '../../utils';
import { Student } from '../../../../interfaces/api';
import { UserDetailsContext } from '../../App';

const UNDEFINED_COUNSELOR = 'Processing...';
const UNDEFINED_COUNSELOR_STYLE = { fontStyle: 'italic', color: 'lightgrey' };

interface TableProps {
  columns: StickyColumn[];
  data: object[];
  counselors?: MenuItem[];
  onAction: (row: Row) => void;
  onFilteredRows: (filteredRows: object[]) => void;
}

interface DropdownFilterProps {
  column: FilterProps<Student>;
}

// Define a default UI for filtering
const DefaultColumnFilter = () => {
  (DefaultColumnFilter as React.FC).displayName = 'DefaultColumnFilter';
  return <div />;
};

// This is a custom filter UI for selecting
// a unique option from a list
export const getDropdownFilter = (placeholder: string, multiselect: boolean, list?: MenuItem[]) => {
  const DropdownFilter = (props: React.PropsWithChildren<DropdownFilterProps>): JSX.Element => {
    (DropdownFilter as React.FC).displayName = 'DropdownFilter';
    const { column } = props;
    const setFilter = column.setFilter;

    const filterItems = (item: MenuItem | DropdownItemMulti) => {
      if (multiselect) {
        (setFilter as unknown as (columnId: string[], updater: null) => void)(
          (item as DropdownItemMulti).values?.map((v: MenuItem) => v.key) as string[],
          null
        );
      } else {
        setFilter((item as MenuItem).value as string, null);
      }
    };

    const dropdownProps = {
      list: list || [],
      placeholder,
      size: 'flat',
      icon: 'angle',
      color: 'grey',
    } as DropdownProps;

    return (
      <div className="dropdown-filter">
        {multiselect ? (
          <MultiSelectDropdown {...dropdownProps} menuItemsOnClick={filterItems} />
        ) : (
          <Dropdown {...dropdownProps} menuItemOnClick={filterItems} />
        )}
      </div>
    );
  };
  return DropdownFilter;
};

export const TableWithFixedColumns = (props: React.PropsWithChildren<TableProps>): JSX.Element => {
  (TableWithFixedColumns as React.FC).displayName = 'TableWithFixedColumns';

  const { canEditSRIDashboard } = useContext(UserDetailsContext);
  const editActionIconProps = canEditSRIDashboard
    ? { name: 'pencil' }
    : { name: 'pencil', color: 'grey' as IconColor };

  const { columns, data, counselors, onAction, onFilteredRows } = props;
  const isDistrictLevel = getUserContextProps().isDistrictLevel;
  const mainColumn = [...(counselors as MenuItem[])];
  const mainColumnName = 'Select Counselor';

  const defaultColumn: Partial<StickyColumn> = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const sortType = React.useMemo(
    () => (rowA: Row<Student>, rowB: Row<Student>) => {
      let nameCompare = rowA.original.lastName.localeCompare(rowB.original.lastName);
      if (nameCompare === 0) {
        nameCompare = rowA.original.firstName.localeCompare(rowB.original.firstName);
      }
      return nameCompare;
    },
    []
  );

  const statusMap = React.useMemo(() => {
    const map = new Map<STATUS, string>();
    STATUSES.forEach((status) => map.set(status.key, status.value));
    return map;
  }, []);

  function filterIncludes(
    rows: Row<Student>[],
    ids: Array<string>,
    filterValue: string | string[]
  ) {
    const isMultiSelect = typeof filterValue === 'object';
    let filteredRows = rows;

    if (isMultiSelect) {
      const showAll = filterValue.length < 1 || filterValue.includes(STATUS.ALL);
      if (!showAll) {
        filteredRows = rows.filter((row: Row<Student>) => {
          if (ids[0].indexOf('.') > -1) {
            const [key1, key2] = ids[0].split('.');
            return filterValue.includes(
              (row?.original as unknown as { [key: string]: string })[key1][
                key2 as unknown as number
              ] as string
            );
          }
          return filterValue.includes(row?.original[ids[0] as keyof Student] as string);
        });
      }
    } else {
      let showAll = true;
      if (ids.includes('counselor')) {
        showAll = filterValue === 'Select Counselor' || filterValue === 'All Counselors';
      }
      if (!showAll) {
        filteredRows = rows.filter(
          (row: Row<Student>) => row.original[ids[0] as keyof Student] === filterValue
        );
      }
    }

    onFilteredRows(filteredRows);
    return filteredRows;
  }

  columns.forEach((column: StickyColumn, i: number) => {
    if (i === 0) {
      column.sortType = sortType;
    }
    if (i === 2) {
      if (isDistrictLevel) {
        column.Filter = getDropdownFilter(mainColumnName, false, mainColumn);
        column.filter = filterIncludes;
      }
    }
    if (i > 2 && column.accessor !== 'actions') {
      column.Filter = getDropdownFilter('Select Status', true, STATUSES);
      column.filter = filterIncludes;
      column.sticky = '';
    }
    if (i === 3 && columns.length > 3 && columns[i + 1]?.accessor !== 'actions') {
      column.sticky = 'left';
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            id: 'studentName',
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    useBlockLayout,
    usePagination,
    useSticky
  );
  onFilteredRows(rows);

  return (
    <div className="tableWrap">
      <div {...getTableProps()} className="table sticky">
        <div className="header">
          {headerGroups.map((headerGroup: HeaderGroup, i: number) => (
            <div {...headerGroup.getHeaderGroupProps()} key={i} className="tr">
              {headerGroup.headers.map((header: HeaderGroup, j: number) => {
                const headerClasses = (header as unknown as UseSortByColumnProps<Student>).isSorted
                  ? 'th header-dark-background'
                  : 'th';
                return (
                  <div
                    {...header.getHeaderProps(
                      (header as unknown as UseSortByColumnProps<Student>).getSortByToggleProps()
                    )}
                    key={j}
                    className={headerClasses}
                    title=""
                  >
                    <div>
                      <span title={`${header.render('Header')}`} className="column-header-text">
                        {header.render('Header')}
                      </span>
                      {(header as unknown as UseSortByColumnProps<Student>).isSorted ? (
                        <span className="sort-arrow">
                          {(header as unknown as UseSortByColumnProps<Student>).isSortedDesc ? (
                            <Icon name="sortDown" size={16} />
                          ) : (
                            <Icon name="sortUp" size={16} />
                          )}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      {(header as unknown as UseFiltersColumnProps<Student>).canFilter
                        ? header.render('Filter')
                        : null}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body">
          {page.map((row: Row, i: number) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                key={i}
                className="tr"
                data-test-id={row?.values?.studentName}
              >
                {row.cells.map((cell: Cell, j: number) => {
                  const cellProps = cell.getCellProps();

                  // Reversing the stacking order for the sticky columns, i.e the topmost will have a zIndex than the lower ones.
                  // So the tooltip could be visible below it.
                  const zIndex = (cellProps as unknown as any)['data-sticky-td']
                    ? page.length - i
                    : undefined;

                  if (cell.column.id === 'counselor' && cell.value === 'undefined') {
                    cell.value = UNDEFINED_COUNSELOR;
                    cellProps.style = { ...cellProps.style, ...UNDEFINED_COUNSELOR_STYLE };
                  }

                  return (
                    <div
                      {...cellProps}
                      style={{
                        ...cellProps.style,
                        zIndex,
                      }}
                      key={j}
                      className="td"
                    >
                      {cell.column.id === 'counselor' && cell.value === UNDEFINED_COUNSELOR ? (
                        <>{cell.value}</>
                      ) : (
                        <>
                          {!svgs[cell.value] && cell.column.id !== 'actions' && cell.render('Cell')}
                        </>
                      )}
                      {cell.column.id === 'actions' && (
                        <div
                          data-test="edit-actions"
                          className={`table-icon-cell ${canEditSRIDashboard ? '' : 'disabled'}`}
                        >
                          <button
                            disabled={!canEditSRIDashboard}
                            className="table-icon-button"
                            onClick={() =>
                              onAction(row) as unknown as MouseEventHandler<HTMLDivElement>
                            }
                          >
                            <Icon {...editActionIconProps} />
                          </button>
                        </div>
                      )}
                      {svgs[cell.value] && (
                        <div
                          className="table-icon-cell"
                          data-cy={cell.value}
                          data-for="status-tooltip"
                          data-tip={statusMap.get(cell.value)}
                          data-test={cell?.column?.Header}
                        >
                          <Icon name={cell.value} />
                        </div>
                      )}
                      {!cell.value && cell.column.id !== 'actions' && (
                        <div
                          className="table-icon-cell"
                          data-for="status-tooltip"
                          data-tip={statusMap.get(STATUS.NO_DATA)}
                          data-test={cell?.column?.Header}
                        >
                          <Icon name="no-data" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {rows.length == 0 && (
          <Notice
            noticeType={
              isDistrictLevel ? NOTICE_TYPE.NO_STUDENT_DISTRICT : NOTICE_TYPE.NO_STUDENT_SCHOOL
            }
          />
        )}
      </div>
      <div className="pagination">
        <div>
          <div className="items-per-page">Items per page:</div>
          <div>
            <Dropdown
              list={[20, 30, 50, 100].map((i) => ({ key: '' + i, value: '' + i }))}
              menuItemOnClick={(e: MenuItem) => {
                setPageSize(Number(e.value));
              }}
              size="full"
              icon="chevronDown"
              iconColor="blue"
              hideBorder
              placeHolderColor="black"
              opensUp
            />
          </div>
          <div className="divider"></div>
          <div className="show-items">
            {(pageIndex > 1 ? pageIndex : 1) * (pageIndex ? pageSize : 0) + 1}-
            {(pageIndex + 1) * pageSize > rows.length ? rows.length : (pageIndex + 1) * pageSize}
            &nbsp; of {rows.length} students
          </div>
        </div>

        <div>
          <div className="divider" />
          <div className="show-items-per-page">
            <div className="page-number-select-wrapper">
              <Dropdown
                list={pageOptions.map((i) => ({ key: '' + (i + 1), value: '' + (i + 1) }))}
                menuItemOnClick={(e: MenuItem) => {
                  if (e) {
                    gotoPage(Number(e.value) - 1);
                  }
                }}
                key={pageIndex}
                placeholder={'' + (pageIndex + 1)}
                size="full"
                icon="chevronDown"
                iconColor="blue"
                hideBorder
                placeHolderColor="black"
                opensUp
              />
            </div>
            <span>&nbsp; of {pageOptions.length} pages </span>
          </div>
          <div className="divider" />
          <div
            onClick={() => {
              if (canPreviousPage) {
                previousPage();
              }
            }}
          >
            <Icon name="caretLeft" size={16} />
          </div>

          <div className="divider"></div>
          <div
            onClick={() => {
              if (canNextPage) {
                nextPage();
              }
            }}
          >
            <Icon name="caretRight" size={16} />
          </div>
        </div>
      </div>
    </div>
  );
};
