const css = `.dd-list {
  position: absolute;
  z-index: 20;
  min-width: calc(100% - 12px);
  width: auto;
  max-height: 215px;
  border: 1px solid #677274;
  border-radius: 3px;
  box-shadow: 0 2px 5px -1px #677274;
  background-color: white;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  padding: 5px;
  overflow-y: auto;
  margin-top: 32px;
}

.dd-list-justify-right {
  right: 0;
}

.dd-list-above {
  top: unset;
  bottom: 32px;
}

.dd-list-item {
  position: relative;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  margin-left: 3px;
  width: auto;
  min-width: calc(100% - 5px);
  height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: white;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  line-height: 14px;
}

.dd-item-list-export-dropdown {
  height: 40px !important;
  white-space: normal;
  line-height: 21px !important;
}

.dd-list-item.dd-multi-selected {
  background-color: lightgrey;
  border: 1px solid white;
  color: white;
}

.dd-list-item.dd-selected:hover {
  background-color: lightgrey;
  color: white;
}

.dd-list-item:hover {
  background-color: #0075db;
  color: white;
}

.dd-list-item-disabled:hover {
  background-color: white !important;
  color: lightgrey !important;
}

.dd-checkbox {
  position: relative;
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  border: 1px solid #aaaaaa !important;
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  vertical-align: middle;
  margin-top: -4px;
}

.dd-checked {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 43%;
  transform: translate(-50%, -50%);
}

.dd-list-text-static {
  font-size: small;
  font-weight: bold;
  padding: 5px 0 5px 5px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL01lbnVMaXN0Iiwic291cmNlcyI6WyJtZW51bGlzdC5zY3NzIiwiLi4vLi4vZGVmaW5pdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFLQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFLGtCQ3RFYTtFRHVFYjs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
