import './header.scss';
import React from 'react';

export interface Props {
  appDisplayName: string;
}

export const Header = (props: Props): JSX.Element => {
  const { appDisplayName } = props;
  (Header as React.FC).displayName = 'Header';
  return <div className="banner-div">{appDisplayName}</div>;
};
