import { apiClient, getUserContextState } from '../utils';
import {
  GET_DISTRICT_ENDPOINT,
  GET_SCHOOL_ENDPOINT,
  UPDATE_STUDENT_INDICATORS_ENDPOINT,
  IS_LOCAL_DEV,
  EXPORT_ALL_STUDENTS_ENDPOINT,
} from '../constants';
import { IndicatorStatus, StudentIndicatorsRequest } from '../../../interfaces/api';

export const getStudentDetails = async (
  districtId: string | null | undefined,
  schoolId: string | null | undefined,
  classYear: number
) => {
  const baseUrl: string = districtId ? GET_DISTRICT_ENDPOINT : GET_SCHOOL_ENDPOINT;
  const districtPart: string = districtId ? `/${districtId}/schools` : '';
  const schoolPart: string = schoolId ? `/${schoolId}` : '';
  const url = `${baseUrl}${districtPart}${schoolPart}/students/${classYear}`;

  // TODO:: use querystring for state only for mocks - remove once real API in place
  const state = getUserContextState() || 'oh';
  const students = await apiClient({
    url: `${url}?state=${state}`, // QS param only for mock data.
    method: 'GET',
  });

  return students;
};

export const updateStudentIndicators = async (
  studentId: string,
  schoolId: string,
  classYear: number,
  indicators: { [key: string]: IndicatorStatus }
) => {
  const data: StudentIndicatorsRequest = {
    indicators,
  };

  // TODO:: make local POST for UPDATE work; until then, just re-GET the student data for effect
  if (IS_LOCAL_DEV) {
    return await getStudentDetails(null, schoolId, classYear);
  }
  const url = `${UPDATE_STUDENT_INDICATORS_ENDPOINT}/${schoolId}/students/${classYear}/${studentId}`;

  return await apiClient({
    url: url,
    method: 'PUT',
    data,
  });
};

export const exportAllStudents = async (classYear?: string) => {
  if (!classYear) {
    throw new Error('No Class Year Provided For Export All');
  }
  const url = `${EXPORT_ALL_STUDENTS_ENDPOINT}/${classYear}`;

  const result = await apiClient({
    url,
    method: 'GET',
  });

  return result;
};
