export const pushState = (hash: string) => {
  const url = window.location.href.replace(window.location.hash, '');
  if (window.history.pushState) {
    if (window.history.state?.url !== `${url}#${hash}`) {
      window.history.pushState({ url: `${url}#${hash}` }, `${url}#${hash}`, `${url}#${hash}`);
    }
  } else {
    window.location.hash = `#${hash}`;
  }
};

const noop = () => true;

type PageNavFunction = (realPageName: any, hash: string, requiresPushState: boolean) => void;

type NavFunctionAnon = () => void;

export const addPageNavFunction = (
  pageNameOrTabAlias: string,
  callBackFunction: PageNavFunction,
  realPageName: any,
  hash: string,
  requiresPushState: boolean
) => {
  if (!window.SRI_STAFF_UI_CONTEXT.pageNavFunctions) {
    window.SRI_STAFF_UI_CONTEXT.pageNavFunctions = new Map<string, NavFunctionAnon>();
  }
  window.SRI_STAFF_UI_CONTEXT.pageNavFunctions.set(pageNameOrTabAlias, () =>
    callBackFunction(realPageName, hash, requiresPushState)
  );
};

type TabNavFunction = (realTabName: string, requiresPushState: boolean) => void;

export const addTabNavFunction = (
  hash: string,
  callBackFunction: TabNavFunction,
  realTabName: string,
  requiresPushState: boolean
) => {
  if (!window.SRI_STAFF_UI_CONTEXT.tabNavFunctions) {
    window.SRI_STAFF_UI_CONTEXT.tabNavFunctions = new Map<string, NavFunctionAnon>();
  }
  window.SRI_STAFF_UI_CONTEXT.tabNavFunctions.set(hash, () =>
    callBackFunction(realTabName, requiresPushState)
  );
};

export const useHashRouting = () => {
  window.onhashchange = () => {
    const hash = window.location.hash.replace('#', '');
    if (window.SRI_STAFF_UI_CONTEXT?.pageNavFunctions) {
      if (window.SRI_STAFF_UI_CONTEXT.pageNavFunctions.get(`${hash}`)) {
        (window.SRI_STAFF_UI_CONTEXT.pageNavFunctions.get(`${hash}`) || noop)();
      }
    }
    if (window.SRI_STAFF_UI_CONTEXT?.tabNavFunctions) {
      if (window.SRI_STAFF_UI_CONTEXT.tabNavFunctions.get(`${hash}`)) {
        (window.SRI_STAFF_UI_CONTEXT.tabNavFunctions.get(`${hash}`) || noop)();
      }
    }
  };
};
