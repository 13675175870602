const css = `.search-div {
  margin-left: 10px;
}

.search-div .label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.search-div input {
  position: relative;
  max-width: none;
  width: 342px;
  height: 32px;
  left: 10px;
  top: 0;
  border: 1px solid #838e91;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 10px 0;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding-left: 10px;
}

.search-div input::placeholder {
  height: 21px;
  left: 10px;
  top: 5px;
  font-size: 14px;
  line-height: 21px;
  color: #677274;
}

.search-div .icon {
  display: inline-block;
  position: relative;
  right: 18px;
  top: 7px;
}

.search-div .icon .sri-icon-inner > svg {
  width: 24px !important;
  height: 24px !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL1NlYXJjaCIsInNvdXJjZXMiOlsic2VhcmNoLnNjc3MiLCIuLi8uLi9kZWZpbml0aW9ucy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0EsT0NIaUI7OztBRE1uQjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLE9DbkJpQjtFRG9CakI7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLE9DOUJZOzs7QURpQ2Q7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
