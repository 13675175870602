import './student-details.scss';
import React, { useState } from 'react';
import { Search } from '../Search';
import { MenuItem } from '../Menu';
import { TableWithFixedColumns } from '../../containers/TableWithFixedColumn';
import { LabeledIcon } from '../LabeledIcon';
import { Row } from 'react-table';
import { IconSvg } from '../Icon';
import { StickyColumn, STATUSES } from '../../constants';
import { Popover } from '../Popover';
import { Drawer } from '../Drawer';
import { StudentEdit } from '../StudentEdit';
import { Indicator, updateStudentIndicators } from '../../api';
import { IndicatorStatus, Student } from '../../../../interfaces/api';
import { LoaderItem } from '../Loader';
import { ToastrItem } from '../Toastr';
import { getUserContextHighSchoolId } from '../../utils';
import ReactTooltip from 'react-tooltip';
import { FeatureFlagWrapper } from '../FeatureFlagWrapper';

import { FeatureFlags } from '../../api/featureFlags';

export interface StudentDetailsProps {
  isDistrictLevel: boolean;
  schoolOrCounselorTableColumns: StickyColumn[];
  schoolOrCounselorTableData: object[];
  counselors?: MenuItem[];
  indicatorKey?: string;
  indicators: Indicator[];
  indicatorsMap: { [key: string]: Indicator };
  selectedSchoolId?: string;
  selectedSchool?: MenuItem;
  showToastr: (toastr: ToastrItem) => void;
  showLoader: (loader: LoaderItem) => void;
  getFilteredTableData?: (filteredRows: object[]) => void;
  updateHasStudentReadinessData?: () => void;
  hasStudentReadinessData?: boolean;
}
interface IndicatorDataField {
  [key: string]: IndicatorStatus;
}

export const StudentDetails = (
  props: React.PropsWithChildren<StudentDetailsProps>
): JSX.Element => {
  (StudentDetails as React.FC).displayName = 'StudentDetails';

  const {
    schoolOrCounselorTableColumns,
    schoolOrCounselorTableData,
    counselors,
    indicatorKey,
    indicators,
    indicatorsMap,
    selectedSchool,
    showToastr,
    showLoader,
    getFilteredTableData,
    updateHasStudentReadinessData,
    hasStudentReadinessData,
    isDistrictLevel,
  } = props;

  const [searchStudentNameOrId, setsearchStudentNameOrId] = useState<string>();
  const [showManualEdit, setShowManualEdit] = useState<boolean>(false);
  const [studentData, setStudentData] = useState<Row>();
  const [areChangesPending, setChangesPending] = useState<boolean>(false);
  const [indicatorsToBeUpdated, setIndicatorsToBeUpdated] = useState<IndicatorDataField>();

  const searchStudents = (query: string) => {
    setsearchStudentNameOrId(query);
  };

  const onActionClick = (row: Row) => {
    setShowManualEdit(true);
    setStudentData(row);
  };

  const closeDrawerClickHandler = () => {
    setChangesPending(false);
    setShowManualEdit(false);
  };

  const saveStudentManualData = async () => {
    const student = studentData?.original as Student;

    try {
      showLoader({ loading: true });
      showToastr({ show: false });
      const updatedStudent: Student = await updateStudentIndicators(
        student.id,
        (selectedSchool?.key as string) || getUserContextHighSchoolId(),
        student.classYear,
        indicatorsToBeUpdated as IndicatorDataField
      );
      const studentIndex = schoolOrCounselorTableData.findIndex((row: object) => {
        return (row as { [key: string]: string }).id === student.id;
      });

      if (studentIndex > -1) {
        schoolOrCounselorTableData[studentIndex] = {
          ...student,
          results: { ...student.results, ...updatedStudent.results },
        };
      }
      if (!hasStudentReadinessData && updateHasStudentReadinessData) {
        updateHasStudentReadinessData();
      }
    } catch (error) {
      showToastr({ show: true });
    } finally {
      showLoader({ loading: false });
    }

    setIndicatorsToBeUpdated({});
    setChangesPending(false);
    setShowManualEdit(false);
  };

  const onUpdateIndicatorStatus = (indicator: string, status: IndicatorStatus) => {
    setIndicatorsToBeUpdated({ ...indicatorsToBeUpdated, [indicator]: status });
    setChangesPending(true);
  };

  // search global filter
  let filterSchoolOrCounselorTableData = schoolOrCounselorTableData;
  if (searchStudentNameOrId) {
    filterSchoolOrCounselorTableData = schoolOrCounselorTableData.filter((row: object) => {
      return (
        (row as { [key: string]: string }).studentName
          .toLowerCase()
          .includes(searchStudentNameOrId.toLowerCase()) ||
        (row as { [key: string]: string }).hsStudentId
          .toLowerCase()
          .includes(searchStudentNameOrId.toLowerCase())
      );
    });
  }

  return (
    <div className="student-details">
      <ReactTooltip id="status-tooltip" effect="solid" type="dark" place="top" />

      <ReactTooltip id="status-tooltip-disabled" effect="solid" type="dark" place="top">
        To edit test scores,
        <br /> navigate to the
        <br /> student folder.
      </ReactTooltip>

      <div className="search-status-table-container">
        <div>
          <div className="student-search-and-status-key-container">
            <Search
              placeholder="Search by Name or ID"
              labelName="Student Search"
              submitQuery={searchStudents}
            />
            <div className="status-key-container">
              <span className="status-key">Status Key</span>
              <Popover icon={'help'} justifyRight>
                <div className="status-key-popover-content" id="status-key-popover-content-id">
                  {[...STATUSES].reverse().map((status) => (
                    <LabeledIcon
                      className="status-key-labeled-icon"
                      key={status.key}
                      iconName={status.key as IconSvg}
                      label={status.value}
                      labelSide="right"
                    />
                  ))}
                </div>
              </Popover>
            </div>
          </div>
        </div>
        <TableWithFixedColumns
          columns={[
            ...schoolOrCounselorTableColumns,
            {
              Header: 'Actions',
              accessor: 'actions',
              sticky: 'right',
              disableSortBy: true,
            },
          ]}
          data={filterSchoolOrCounselorTableData}
          counselors={counselors}
          onAction={onActionClick}
          onFilteredRows={(filteredRows) =>
            getFilteredTableData && getFilteredTableData(filteredRows)
          }
        />
      </div>
      {showManualEdit && (
        <Drawer
          title={studentData?.values.studentName}
          drawerOnRightSide={true}
          secondaryButtonOnRightSide={true}
          secondaryButtonText="Cancel"
          primaryButtonText="Save"
          isPrimaryButtonDisabled={!areChangesPending}
          saveClickHandler={saveStudentManualData}
          closeDrawerClickHandler={closeDrawerClickHandler}
        >
          <FeatureFlagWrapper flagName={FeatureFlags.v2RulesUpdates} propName="v2RulesUpdates">
            <StudentEdit
              isDistrictLevel={isDistrictLevel}
              studentResults={(studentData?.original as Student).results}
              indicatorKey={indicatorKey}
              indicators={indicators}
              indicatorsMap={indicatorsMap}
              onUpdateIndicator={onUpdateIndicatorStatus}
              scores={(studentData?.original as Student).scores}
              studentId={(studentData?.original as Student).id}
            />
          </FeatureFlagWrapper>
        </Drawer>
      )}
    </div>
  );
};
