import { apiClient, getUserContextHighSchoolId } from '../utils';
import { GET_SCHOOL_ENDPOINT } from '../constants';
import { Counselor, ProvisionStatus, School } from '../../../interfaces/api';
import { MenuItem } from '../components/Menu';

export const getSchool = async () => {
  const schoolId = getUserContextHighSchoolId();
  const url = `${GET_SCHOOL_ENDPOINT}/${schoolId}`;
  const school = (await apiClient({ url, method: 'GET' })) as School;

  let counselors: MenuItem[] = [];
  const allCounselors: MenuItem = { key: '1', value: 'All Counselors' };
  const unassigned: MenuItem = { key: '0', value: 'Unassigned' };
  const classYears: MenuItem[] = [];

  const provisionStatus: ProvisionStatus = school.provisionedStatus;
  const hasStudentReadinessData: boolean = school.hasStudentReadinessData;
  const hasImportedData: boolean = school.hasImportedData;
  const schoolName: string = school.name;

  if (provisionStatus === ProvisionStatus.COMPLETED) {
    const counselorsList = school?.counselors || [];
    counselorsList.forEach((counselor: Counselor) => {
      const newCounselor: MenuItem = {
        key: counselor.id,
        value: counselor.name,
      };

      counselors.push(newCounselor);
    });

    const schoolClassYears = school?.classYears || [];
    schoolClassYears.forEach((classYear: number) => {
      classYears.push({
        key: `${classYear}`,
        value: `Class of ${classYear}`,
      });
    });
  }

  classYears.sort((a, b) => parseInt(a.key) - parseInt(b.key));
  counselors.sort((a, b) => (a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1));
  counselors = [allCounselors, ...counselors, unassigned];

  return {
    provisionStatus,
    hasStudentReadinessData,
    hasImportedData,
    counselors,
    classYears,
    schoolName,
  };
};
