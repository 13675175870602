import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Icon } from '../../components/Icon';
import './managePage.scss';
import ReactTooltip from 'react-tooltip';

import ReactQuill from 'react-quill';
import { BoundsStatic, Delta, DeltaStatic, RangeStatic, Sources } from 'quill';

import 'react-quill/dist/quill.snow.css';

import { states } from '../../utils/states';
import {
  apiClient,
  getUserContextDistrictId,
  getUserContextHighSchoolId,
  getUserContextInstitutionName,
  getUserContextState,
  getUserContextStudentGradeLevelEnabled,
} from '../../utils';
import { Drawer } from '../../components/Drawer';
import { getSchool, NavianceSchool } from '../../api';
import { SelectSchoolTable } from './selectSchoolTable';
import {
  ACTIVATE_SCHOOL_ENDPOINT,
  GET_DISTRICT_ENDPOINT,
  NAVIANCE_SUPPORT_LINK,
  DISTRICT_DATA_IMPORT,
  SCHOOL_DATA_IMPORT,
} from '../../constants';
import {
  District,
  ProvisionResponse,
  ProvisionSchool,
  ProvisionSchoolResponse,
  ProvisionStatus,
  School,
  SchoolProvisionStatus,
} from '../../../../interfaces/api';
import { Loader } from '../../components/Loader';
import { Confirmation } from '../../components/Confirmation';

import {
  getDistrictStudentMessage,
  postDistrictStudentMessage,
  getSchoolStudentMessage,
  postSchoolStudentMessage,
} from '../../api/studentMessage';
import { UserDetailsContext } from '../../App';

export interface ManagePageProps {
  gotoMainPage: () => void;
  isDistrictLevel: boolean;
  districtHighschools: NavianceSchool[] | null;
}

/* We need to copy this interface because current version of React Quill
   does not allow access to this interface
   https://github.com/zenoamaro/react-quill/issues/640
   */
export interface UnprivilegedEditor {
  getLength(): number;
  getText(index?: number, length?: number): string;
  getHTML(): string;
  getBounds(index: number, length?: number): BoundsStatic;
  getSelection(focus?: boolean): RangeStatic;
  getContents(index?: number, length?: number): DeltaStatic;
}

const STUDENT_MESSAGE_MAX_LENGTH = 400;

export const ManagePage = (props: ManagePageProps): JSX.Element => {
  (ManagePage as React.FC).displayName = 'ManagePage';
  const { gotoMainPage, isDistrictLevel, districtHighschools } = props;
  const { canImportData } = useContext(UserDetailsContext);

  // Default to undefined to allow api request to finish showing the status
  const [schoolActivationStatus, setSchoolActivationStatus] = useState<ProvisionStatus | undefined>(
    undefined
  );
  const [schoolImportStatus, setSchoolImportStatus] = useState<boolean | undefined>(undefined);
  const [hideNoImportNotice, setHideNoImportNotice] = useState<boolean>(false);

  const [showActivateDistrictSchool, setShowActivateDistrictSchool] = useState<boolean>(false);
  const [showActivateSchool, setShowActivateSchool] = useState<boolean>(false);
  const [sriSchools, setSriSchools] = useState<School[] | null>(null);
  const [selectedDistrictSchools, setSelectedDistrictSchools] = useState<string[]>([]);
  const [htmlMessageForStudent, setHtmlMessageForStudent] = useState('');
  const [messageForStudentExceededMaxLength, setMessageForStudentExceededMaxLength] =
    useState(false);
  const [messageForStudentCharactersLeft, setMessageForStudentCharactersLeft] = useState(400);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [showActivationErrorMessage, setShowActivationErrorMessage] = useState<boolean>(false);
  const [showProvisionStatusLoader, setShowProvisionStatusLoader] = useState<boolean>(false);
  const [allSchoolsFailed, setAllSchoolsFailed] = useState<boolean>(false);
  const isSchoolLevel = !isDistrictLevel;

  const loadMessageForStudent = async () => {
    if (isDistrictLevel) {
      const district = getUserContextDistrictId();
      const data = await getDistrictStudentMessage(district);
      setHtmlMessageForStudent(data.message);
    } else {
      //currently set to high school id instead of school id
      const school = getUserContextHighSchoolId();
      const data = await getSchoolStudentMessage(school);
      setHtmlMessageForStudent(data.message);
    }
  };

  const saveMessageForStudent = async (message: string) => {
    setIsSavingChanges(true);
    if (isDistrictLevel) {
      const district = getUserContextDistrictId();
      await postDistrictStudentMessage(district, message)
        .catch((err) => {
          console.log('Error encountered while saving message');
        })
        .finally(() => {
          setIsSavingChanges(false);
        });
    } else {
      //currently set to high school id instead of school id
      const school = getUserContextHighSchoolId();
      await postSchoolStudentMessage(school, message)
        .catch((err) => {
          console.log('Error encountered while saving message');
        })
        .finally(() => {
          setIsSavingChanges(false);
        });
    }
  };

  const getDistrictSchoolsProvisionStatuses = async (modalCallback?: () => void) => {
    apiClient({
      url: `${GET_DISTRICT_ENDPOINT}/${getUserContextDistrictId()}`,
      method: 'GET',
    })
      .then((district: District) => {
        setShowProvisionStatusLoader(false);
        setSriSchools(district.schools);
        if (
          district.schools.length > 0 &&
          district.schools.some((school) => school.provisionedStatus === ProvisionStatus.FAILED)
        ) {
          setShowActivationErrorMessage(true);
        }
        if (
          district.schools.length > 0 &&
          !district.schools.some((school) => school.provisionedStatus !== ProvisionStatus.FAILED)
        ) {
          setAllSchoolsFailed(true);
        }
        if (modalCallback) {
          modalCallback();
        }
      })
      .catch((error) => {
        setShowProvisionStatusLoader(false);
        // Handle 404 error as expected, because this district may not be in SRI.
        if (modalCallback) {
          modalCallback();
        }
        if (error.response.status == 404) {
          setSriSchools([]);
        } else {
          throw error;
        }
      });
  };

  const getSchoolProvisionStatus = async () => {
    getSchool()
      .then(({ provisionStatus, hasImportedData }) => {
        setSchoolActivationStatus(provisionStatus);
        setSchoolImportStatus(hasImportedData);
        setShowProvisionStatusLoader(false);
        if (provisionStatus === ProvisionStatus.FAILED) {
          setShowActivationErrorMessage(true);
        }
      })
      .catch((error) => {
        setShowProvisionStatusLoader(false);
        // Handle 404 error as expected, because this district may not be in SRI.
        // Don't throw an error because this is expected
        if (error.response.status == 404) {
          setSchoolActivationStatus(ProvisionStatus.NOT_REQUESTED);
        }
      });
  };

  // initialize the data.
  useEffect(() => {
    if (isDistrictLevel) {
      // Load the district details from SRI to know which schools have already been activated.
      getDistrictSchoolsProvisionStatuses();
    } else {
      // Load the school provision status from api
      getSchoolProvisionStatus();
    }
    loadMessageForStudent();
  }, []);

  const stateAbbrev = getUserContextState();
  const stateName = (stateAbbrev && states[stateAbbrev.toUpperCase()]) || '';
  const getSchoolIdsByProvisionedStatus = (status: ProvisionStatus) =>
    sriSchools?.filter((school) => school.provisionedStatus == status)?.map((school) => school.id);
  const activatedCompletedSchoolIds: string[] | undefined = getSchoolIdsByProvisionedStatus(
    ProvisionStatus.COMPLETED
  );
  const activationInProgressSchoolIds: string[] | undefined = getSchoolIdsByProvisionedStatus(
    ProvisionStatus.IN_PROGRESS
  );
  const activationFailedSchoolIds: string[] | undefined = getSchoolIdsByProvisionedStatus(
    ProvisionStatus.FAILED
  );

  const showImportInstructions: boolean = isDistrictLevel
    ? !!sriSchools?.length && !sriSchools.some((school) => school.hasImportedData)
    : schoolImportStatus === false;
  const allDistrictSchools = useMemo<{ id: string; name: string }[]>(() => {
    const combinedSchools: { id: string; name: string }[] = [];
    if (sriSchools && districtHighschools) {
      // add all the naviance District High schools
      combinedSchools.push(
        ...districtHighschools.map((school) => ({ id: school.nid, name: school.name }))
      );
      // add any SRI school that are not already included
      sriSchools.forEach((activatedSchool) => {
        if (!combinedSchools.find((check) => check.id === activatedSchool.id)) {
          combinedSchools.push(activatedSchool);
        }
      });
    }
    return combinedSchools;
  }, [sriSchools, districtHighschools]);

  const activateDistrictSchoolsClicked = () => {
    setAllSchoolsFailed(false);
    if (districtHighschools?.length) {
      setShowActivationErrorMessage(false);
      const schoolsToSave: ProvisionSchool[] = districtHighschools
        .map((school) => ({ schoolId: school.nid, schoolName: school.name }))
        .filter((school) => selectedDistrictSchools.includes(school.schoolId));

      apiClient({
        url: `${GET_DISTRICT_ENDPOINT}/${getUserContextDistrictId()}/schools`,
        method: 'POST',
        data: {
          state: getUserContextState(),
          schools: schoolsToSave,
        },
      })
        .then(({ schoolStatuses }: ProvisionResponse) => {
          addActivatedSchools(schoolStatuses);
          getDistrictSchoolsProvisionStatuses();
        })
        .catch((error) => {
          const { schoolStatuses, errorIds }: ProvisionResponse = error.response.data;
          addActivatedSchools(schoolStatuses);
          getDistrictSchoolsProvisionStatuses();
          if (errorIds?.length) {
            setShowActivationErrorMessage(true);
            // TODO: Show error message (Error component)
            console.log({ errorIds });
          }
        });
    }
    setShowActivateDistrictSchool(false);
  };

  const addActivatedSchools = (schools: SchoolProvisionStatus[]) => {
    if (schools?.length) {
      const newActivatedSchools: School[] = schools.map((school) => ({
        id: school.schoolId,
        name: school.schoolName,
        provisionedStatus: school.provisionStatus,
        hasStudentReadinessData: false,
        hasImportedData: false,
        counselors: [],
        classYears: [],
      }));
      setSriSchools([...(sriSchools || []), ...newActivatedSchools]);
    }
  };

  const activateClicked = async () => {
    if (isDistrictLevel) {
      if (
        districtHighschools != null &&
        activatedCompletedSchoolIds != null &&
        activationInProgressSchoolIds
      ) {
        setShowProvisionStatusLoader(true);
        // set all schools as selected.
        getDistrictSchoolsProvisionStatuses(() => {
          setShowProvisionStatusLoader(false);
          setSelectedDistrictSchools(
            districtHighschools
              .map((school) => school.nid)
              .filter(
                (id) =>
                  !activatedCompletedSchoolIds.includes(id) &&
                  !activationInProgressSchoolIds.includes(id)
              )
          );

          setShowActivateDistrictSchool(true);
        });
      }
    } else {
      setShowActivateSchool(true);
    }
  };

  const saveChangesButtonClicked = () => {
    saveMessageForStudent(htmlMessageForStudent);
  };

  const closeDrawerClickHandler = () => {
    setShowActivateDistrictSchool(false);
  };

  const activateSchool = () => {
    setShowActivationErrorMessage(false);
    apiClient({
      url: `${ACTIVATE_SCHOOL_ENDPOINT}/${getUserContextHighSchoolId()}`,
      method: 'POST',
      data: {
        state: getUserContextState(),
        schoolName: getUserContextInstitutionName(),
      },
    })
      .then(({ provisionStatus }: ProvisionSchoolResponse) => {
        setSchoolActivationStatus(provisionStatus);
        if (provisionStatus === ProvisionStatus.FAILED) {
          setShowActivationErrorMessage(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setShowActivationErrorMessage(true);
        }
        // TODO: Show error message (Error component)
      });
    setShowActivateSchool(false);
  };

  const closeConfirmationClickHandler = () => {
    setShowActivateSchool(false);
  };

  const renderSchoolActivationStatus = () => {
    switch (schoolActivationStatus) {
      case ProvisionStatus.COMPLETED:
        return (
          <button disabled className="button-active-completed">
            Active
          </button>
        );
      case ProvisionStatus.IN_PROGRESS:
        return (
          <div className="school-status">
            <div
              className={showProvisionStatusLoader ? 'inprogress-spin-loader' : ''}
              onClick={() => {
                setShowProvisionStatusLoader(true);
                getSchoolProvisionStatus();
              }}
            >
              <Icon name="InProgress" />
            </div>
            Activation in progress
          </div>
        );
      case ProvisionStatus.NOT_REQUESTED:
      case ProvisionStatus.FAILED:
        return (
          <button className="button-activation" onClick={activateClicked}>
            Activate
          </button>
        );
      default:
        return '';
    }
  };
  /**
   * Handle wysiwyg editor change.
   */
  const studentMessageChanged = (
    content: string,
    delta: Delta,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    //We need the lenght of the raw text, not the html content.
    const text = editor.getText();
    setHtmlMessageForStudent(content);
    setMessageForStudentExceededMaxLength(text.trim().length > STUDENT_MESSAGE_MAX_LENGTH);
    setMessageForStudentCharactersLeft(
      Math.max(0, STUDENT_MESSAGE_MAX_LENGTH - text.trim().length)
    );
  };

  const renderActivationErrorMessage = () => {
    return (
      <div className="activation-error">
        <div className="activation-error-inner">
          <Icon name="activationError" size={16} />
          <div className="activation-error-title">Activation Error</div>
          <div className="activation-error-explanation">
            There was a problem activating {stateName} Student Readiness Indicators. Please try
            again. If the problem persists, contact{' '}
            <a
              className="support-link"
              href={NAVIANCE_SUPPORT_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Naviance Support
            </a>
            .
          </div>
          <div
            className="activation-error-close-icon"
            onClick={() => setShowActivationErrorMessage(false)}
          >
            <Icon name="navigationClose" size={16} />
          </div>
        </div>
      </div>
    );
  };

  const isSriEnabledForStudents = getUserContextStudentGradeLevelEnabled();

  const saveButtonEnabled =
    isSriEnabledForStudents && !messageForStudentExceededMaxLength && !isSavingChanges;

  return (
    <>
      {showActivateSchool && (
        <Confirmation
          title={'Activate Student Readiness Indicators?'}
          primaryButtonText={'Activate'}
          closeConfirmationClickHandler={closeConfirmationClickHandler}
          confirmationClickHandler={activateSchool}
        >
          The staff dashboard for Student Readiness Indicators will be activated for your school,
          which will include students in grade 7 and higher. Depending on the size of your school,
          the activation process may take up to one hour.
        </Confirmation>
      )}
      <div className="sri-manage">
        <div className="breadcrumb">
          <div className="breadcrumb-base">
            <a className="goto-main" onClick={gotoMainPage}>
              Student Readiness Indicators
            </a>
            <Icon name="chevronRight" size={16} />
          </div>
          <div className="active-page">
            <span className="active-page-name">Manage Student Readiness Indicators</span>
          </div>
        </div>
        <div className="page-title">Manage Student Readiness Indicators</div>
        <hr className="page-divider" />
        <div className="page-content">
          <div className="welcome-heading">
            Welcome to the Student Readiness Indicators tool! Use this tool to measure progress
            toward high school student readiness.
          </div>
          <div>
            <div className="section-heading">
              Activate Staff Tracking for Student Readiness Indicators
            </div>
            <div className="section-description">
              Data can be updated from Naviance imports or through the{' '}
              <a data-tip data-for="managePage-sri-preview-tooltip" className="tooltip-trigger">
                Student Readiness Indicators dashboard
              </a>
              .
            </div>
            <ReactTooltip
              id="managePage-sri-preview-tooltip"
              effect="solid"
              type="dark"
              place="top"
              className="hover-tooltip"
            >
              <div className="hover-tooltip-content">
                <div>
                  Student progress can be updated directly through the Student Readiness Indicators
                  dashboard.
                </div>
                <div className="sri-preview">
                  <Icon name="sriPreview" />
                </div>
              </div>
            </ReactTooltip>
            {!showActivationErrorMessage && showImportInstructions && !hideNoImportNotice && (
              <div className="inline-notification">
                <span className="notification-header-noimport">No Data Imported</span>
                <span className="notification-message-noimport">
                  There is no imported data for {stateName} Student Readiness Indicators.{' '}
                  {canImportData ? (
                    <a href={isDistrictLevel ? DISTRICT_DATA_IMPORT : SCHOOL_DATA_IMPORT}>
                      Start an import
                    </a>
                  ) : (
                    <>Start an import from Setup&nbsp;&gt;&nbsp;Data&nbsp;Import&nbsp;New.</>
                  )}
                </span>
                <button className="close-notification" onClick={() => setHideNoImportNotice(true)}>
                  <Icon name="close" size={16} />
                </button>
              </div>
            )}
          </div>
          {showActivationErrorMessage && renderActivationErrorMessage()}
          <div className="activation-section">
            <div className="prod-description">{stateName} Student Readiness Indicators</div>{' '}
            {isDistrictLevel ? (
              <>
                {(!sriSchools || showProvisionStatusLoader) && <Loader size="medium" />}
                {sriSchools && (allSchoolsFailed || sriSchools.length === 0) && (
                  <button onClick={activateClicked}>Activate</button>
                )}
                {sriSchools && sriSchools.length > 0 && !allSchoolsFailed && (
                  <button onClick={activateClicked}>Edit Active Schools</button>
                )}
              </>
            ) : (
              <>{renderSchoolActivationStatus()}</>
            )}
          </div>
          <div>
            <div className="section-heading">Information for Students</div>
            <div className="section-description">
              This information will appear if Student Readiness Indicators is an{' '}
              <a data-tip data-for="managePage-student-preview-tooltip" className="tooltip-trigger">
                available feature on Naviance Student
              </a>
              .{' '}
              {isDistrictLevel && (
                <span>
                  Availability for Naviance Student can be managed per school in the Select and
                  Update Optional Features page.
                </span>
              )}
              {isSchoolLevel && (
                <span>
                  Availability for Naviance Student can be managed in{' '}
                  <a href="/connections/fc/fc.php?sec=2" target="_blank">
                    Optional Features
                  </a>
                  .
                </span>
              )}
              <div className="edit-student-message">
                {!isSriEnabledForStudents && (
                  <div className="inline-notification">
                    {isDistrictLevel
                      ? 'This information will appear if Student Readiness Indicators is an available feature on Naviance Student. Availability for Naviance Student can be managed per school in the Select and Update Optional Features page.'
                      : 'Student Readiness Indicators is currently not available to students.'}
                    {!isDistrictLevel && (
                      <span className="suggested-action">
                        Availability for Naviance Student can be managed in{' '}
                        <a href="/connections/fc/fc.php?sec=2" target="_blank">
                          Optional Features.
                        </a>
                      </span>
                    )}
                  </div>
                )}
                <div className="wysiwig-editor-container">
                  <ReactQuill
                    readOnly={!isSriEnabledForStudents}
                    theme="snow"
                    value={htmlMessageForStudent}
                    onChange={studentMessageChanged}
                    style={{
                      opacity: isSriEnabledForStudents ? 1 : 0.4,
                    }}
                  />
                  <div className="wysiwig-info">
                    <div>
                      {isSriEnabledForStudents && (
                        <p className="characters-left">
                          {messageForStudentCharactersLeft} characters left
                        </p>
                      )}
                      {messageForStudentExceededMaxLength && (
                        <p>Text cannot exceed 400 characters.</p>
                      )}
                    </div>
                    <div>
                      <button
                        className="button-secondary save-button"
                        disabled={!saveButtonEnabled}
                        onClick={saveChangesButtonClicked}
                      >
                        Save Updates
                      </button>{' '}
                      {isSavingChanges && <span>Saving...</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ReactTooltip
              id="managePage-student-preview-tooltip"
              effect="solid"
              type="dark"
              place="top"
              className="hover-tooltip"
            >
              <div className="hover-tooltip-content">
                <div>Students will see readiness indicators and their individual progress.</div>
                <div className="student-preview">
                  <Icon name="studentPreview" />
                </div>
              </div>
            </ReactTooltip>
          </div>
        </div>
      </div>

      {showActivateDistrictSchool && sriSchools && districtHighschools && (
        <Drawer
          title="Activate for Schools"
          drawerOnRightSide={true}
          secondaryButtonOnRightSide={true}
          secondaryButtonText="Cancel"
          primaryButtonText="Activate"
          isPrimaryButtonDisabled={selectedDistrictSchools.length === 0}
          saveClickHandler={activateDistrictSchoolsClicked}
          closeDrawerClickHandler={closeDrawerClickHandler}
        >
          <div className="activate-district-schools-drawer">
            <div className="activate-district-schools-instructions">
              Student Readiness Indicators can be activated for any school in your district that has
              students in grade 7 and higher. Depending on the size of your district, the activation
              process may take up to 1 hour.
            </div>
            <div className="activate-district-schools-subheading">Activate for Schools</div>
            <div className="activate-district-schools-selection-count">
              {selectedDistrictSchools.length} schools selected
            </div>
            {districtHighschools && (
              <SelectSchoolTable
                highschools={allDistrictSchools}
                selectedSchools={selectedDistrictSchools}
                setSelectedSchools={setSelectedDistrictSchools}
                activatedCompletedSchoolIds={activatedCompletedSchoolIds || []}
                inProgressSchoolIds={activationInProgressSchoolIds || []}
                failedSchoolIds={activationFailedSchoolIds || []}
              />
            )}
          </div>
        </Drawer>
      )}
    </>
  );
};
