import './student-edit.scss';
import React, { useState } from 'react';
import { GLOBAL_DEPTH, STATUS, STATUSES } from '../../constants';
import { Indicator } from '../../api';
import { Icon } from '../Icon';
import { LabeledIcon } from '../LabeledIcon';
import { StatusSelector } from '../StatusSelector';
import { StudentResults, StudentScores } from '../../../../interfaces/api/students';
import { IndicatorStatus } from '../../../../interfaces/api';
import { RecurseIndicators } from './recurseIndicators';

export type UpdateIndicatorStatusFunc = (indicator: string, status: IndicatorStatus) => void;

export interface StudentEditProps {
  studentResults: StudentResults;
  indicatorKey?: string;
  indicators: Indicator[];
  indicatorsMap: { [key: string]: Indicator };
  onUpdateIndicator: UpdateIndicatorStatusFunc;
  scores: StudentScores;
  studentId: string;
  v2RulesUpdates?: boolean;
  isDistrictLevel: boolean;
}

const STATUS_MAP = new Map<STATUS, string>();
STATUSES.forEach((status) => STATUS_MAP.set(status.key, status.value));

export const StudentEdit = (props: React.PropsWithChildren<StudentEditProps>): JSX.Element => {
  (StudentEdit as React.FC).displayName = 'StudentEdit';

  const {
    indicatorKey,
    indicators,
    indicatorsMap,
    studentResults,
    scores,
    studentId,
    v2RulesUpdates,
    isDistrictLevel,
  } = props;

  const [studentData, setStudentData] = useState(studentResults);

  const initialExpandedIndicators = [indicatorKey];
  if (v2RulesUpdates && indicatorKey) {
    // expand all parents of indicatorKey
    let expandKey = indicatorKey;
    while (indicatorsMap[expandKey].parentKey) {
      initialExpandedIndicators.push(indicatorsMap[expandKey].parentKey);
      expandKey = indicatorsMap[expandKey].parentKey;
    }
  }

  const [expandedIndicators, setExpandedIndicators] = useState(initialExpandedIndicators);
  const updateIndicatorStatus = (indicator: string, status: IndicatorStatus) => {
    const { onUpdateIndicator } = props;
    setStudentData({ ...studentData, [indicator]: status });
    onUpdateIndicator(indicator, status);
  };

  const onIndicatorClick = (indicatorId: string) => {
    let newExpandedIndicators = [];
    if (expandedIndicators.includes(indicatorId)) {
      newExpandedIndicators = expandedIndicators.filter((id) => {
        return id != indicatorId;
      });
    } else {
      newExpandedIndicators = expandedIndicators.concat(indicatorId);
    }
    setExpandedIndicators(newExpandedIndicators);
  };

  const isExpanded = (indicatorId: string) => {
    return expandedIndicators.includes(indicatorId);
  };

  const getParentKey = (indicatorId: string) => {
    return indicatorsMap[indicatorId].parentKey;
  };

  //display indicator scores if existing
  const printScores = (indicator: Indicator): JSX.Element => {
    const valuesToPrint =
      indicator.children
        ?.map((child) => scores[indicatorsMap[child].key])
        .filter((v) => v !== undefined) || [];

    if (!valuesToPrint.length) {
      return (
        <strong>
          {isDistrictLevel ? (
            <span className="score-district">No Data</span>
          ) : (
            <a className="score" href={`/studentsmain/index/scores-key-test-scores/${studentId}`}>
              No Data
            </a>
          )}
        </strong>
      );
    } else {
      return (
        <>
          {valuesToPrint.reverse().map((value, i) => (
            <strong key={`${indicator.key}_${i}`}>
              {i !== 0 && <span className="score-break"> ; </span>}
              {isDistrictLevel ? (
                <span className="score-district">{value}</span>
              ) : (
                <a
                  className="score"
                  href={`/studentsmain/index/scores-key-test-scores/${studentId}`}
                >
                  {value}
                </a>
              )}
            </strong>
          ))}
        </>
      );
    }
  };

  if (v2RulesUpdates) {
    return (
      <div className="recursive-student-edit">
        <RecurseIndicators
          indicatorsMap={indicatorsMap}
          studentData={studentData}
          onIndicatorClick={onIndicatorClick}
          updateIndicatorStatus={updateIndicatorStatus}
          isExpanded={isExpanded}
          printScores={printScores}
          v2RulesUpdates={v2RulesUpdates}
        />
      </div>
    );
  }
  return (
    <div className="student-edit">
      {indicators &&
        indicatorsMap &&
        studentData &&
        indicators.map((indicator) => (
          <div key={indicator.key}>
            {indicatorsMap[indicator.key].depth < GLOBAL_DEPTH && (
              <div
                className={`tier-card tier-${indicator.depth > 0 ? '1' : '0'}`}
                data-test-id={indicator.value}
              >
                <div className="tier-card-header">
                  <div className="tier-indicator">
                    {indicator.depth > 0 && indicator.children && indicator.children.length > 0 && (
                      <div
                        className={`tier-icon${isExpanded(indicator.key) ? '-expanded' : ''}`}
                        onClick={() => onIndicatorClick(indicator.key)}
                      >
                        <Icon name="angle" color="blue" />
                      </div>
                    )}
                    <div
                      className={
                        getParentKey(indicator.key) &&
                        indicator.children &&
                        indicator.children.length > 0
                          ? `student-edit-breadcrumb`
                          : ``
                      }
                      onClick={() =>
                        indicator.depth > 0 && indicator.children && indicator.children.length > 0
                          ? onIndicatorClick(indicator.key)
                          : {}
                      }
                    >
                      {indicator.value}
                    </div>
                  </div>
                  {!indicator.children || indicator.children.length < 1 ? (
                    <StatusSelector
                      getSelected={(status) => updateIndicatorStatus(indicator.key, status)}
                      className="student-edit-status-selector"
                      status={studentData[indicator.key]}
                      editable={indicatorsMap[indicator.key].editable}
                    />
                  ) : (
                    <LabeledIcon
                      className="tier-status"
                      iconName={studentData[indicator.key]}
                      label={STATUS_MAP.get(studentData[indicator.key] as unknown as STATUS)}
                      labelSide="left"
                    />
                  )}
                </div>
                {!indicator.children || indicator.children.length < 1 ? (
                  <div
                    className="tier-child-description"
                    dangerouslySetInnerHTML={{
                      __html: indicatorsMap[indicator.key].description,
                    }}
                  />
                ) : (
                  isExpanded(indicator.key) && (
                    <div
                      className={`tier-rule ${indicator.depth === 0 && 'tier-rule-left'}`}
                      dangerouslySetInnerHTML={{ __html: indicator.description }}
                    />
                  )
                )}
                {isExpanded(indicator.key) &&
                  indicatorsMap[indicator.key].depth < GLOBAL_DEPTH &&
                  indicatorsMap[indicator.key].depth > 0 &&
                  indicator.children &&
                  indicator.children.map((child) => (
                    <div className="tier-child" key={child}>
                      <div>
                        <strong>{indicatorsMap[child].value}</strong>
                        <br />
                        <span
                          dangerouslySetInnerHTML={{ __html: indicatorsMap[child].description }}
                        ></span>
                      </div>
                      <StatusSelector
                        getSelected={(status) => updateIndicatorStatus(child, status)}
                        className="student-edit-status-selector"
                        status={studentData[child]}
                        editable={indicatorsMap[child].editable}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
