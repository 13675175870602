const css = `.export-dropdown {
  right: 10px;
  display: inline-flex;
  position: relative;
  font-size: 1.6rem;
  user-select: none;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  margin-left: 10px;
}
.export-dropdown .dd-list {
  right: 0;
  width: 14rem;
}
.export-dropdown .sri-modal-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 280px;
}
.export-dropdown .export-all-modal__title {
  font-size: 18px;
  background-color: #f1f4f4;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.export-dropdown .export-all-modal__content {
  height: 177px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px;
  font-size: 15px;
  line-height: 21px;
  font-size: 14px;
  font-family: "Open Sans";
  font-style: normal;
}
.export-dropdown .export-all-modal__actions {
  height: 59px;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 10px;
  border-top: 1px solid #c4c9ca;
}
.export-dropdown .outline-button {
  color: #0075db;
  border: 1px solid #0075db;
  background-color: transparent;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  margin-left: 25px;
  overflow: hidden;
  border-radius: 4px;
  font-size: 16px;
}
.export-dropdown .outline-button:hover {
  cursor: pointer;
}
.export-dropdown .filled-button {
  color: white;
  background-color: #0075db;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  margin-left: 25px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #0075db;
  font-size: 16px;
}
.export-dropdown .filled-button:hover {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL0V4cG9ydERyb3Bkb3duIiwic291cmNlcyI6WyJleHBvcnREcm9wZG93bi5zY3NzIiwiLi4vLi4vZGVmaW5pdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRSxPQ3pEVztFRDBEWDtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBLGtCQzdFVztFRDhFWDtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
