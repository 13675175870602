import axios, { AxiosRequestConfig } from 'axios';
import { BASE_API_URL, IS_STANDALONE_DEV } from '../constants';
import { getUserContextHighSchoolId, getUserContextMockStudents } from './context';

// UI code expects to find `config.json` containing the `apiUrl` for use in connecting to the backend API.
// These are automatically set in the deployment process.
// When running locally the config.json is proxied via /esbuild.ts
let API_URL_CACHED: string;
const getApiUrl = async () => {
  if (API_URL_CACHED) {
    return API_URL_CACHED;
  }
  let devLocalPrefix = '';
  if (IS_STANDALONE_DEV) {
    // use full local url to avoid relative/non-dev issues
    devLocalPrefix = `https://${location.hostname}${location.port ? `:${location.port}` : ':4212'}`;
  } else if (location.host === 'localhost:3000') {
    devLocalPrefix = `http://${location.hostname}:4204`;
  }
  const { data: config } = await axios.get(`${devLocalPrefix}/config.json`);
  API_URL_CACHED = `${config.apiUrl}`;
  return API_URL_CACHED;
};

export const apiClient = async (configs: AxiosRequestConfig) => {
  if (getUserContextMockStudents() === 'true' && configs.url) {
    configs.url += configs.url.indexOf('?') == -1 ? '?' : '&';
    configs.url += 'useMock=true';
  }
  // use the embedding app's window context global for the API_URL if it exists
  const useAxios = axios.create({
    baseURL: BASE_API_URL() ? BASE_API_URL() : await getApiUrl(),
    withCredentials: true,
    headers: {
      'disable-rewrite': '1',
      'header-meta': '1',
      'naviance-highschool-id': getUserContextHighSchoolId(),
    },
  });
  const response = await useAxios.request(configs);
  return response.data;
};
