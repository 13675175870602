const css = `.footer-text {
  font-family: "Open Sans", sans-serif;
  line-height: 16px;
  font-size: 10px;
  margin-top: 14px;
  text-align: center;
  color: #677274;
  font-weight: 400;
  margin-bottom: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL0Zvb3RlciIsInNvdXJjZXMiOlsiZm9vdGVyLnNjc3MiLCIuLi8uLi9kZWZpbml0aW9ucy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLE9DRlk7RURHWjtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
