const css = `.po-wrapper {
  display: inline-flex;
  position: relative;
  user-select: none;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}

.po-button {
  top: -2px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: none;
  margin-top: 0;
  margin-left: 2px;
  margin-bottom: 0;
  margin-right: -2px;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  background-color: none;
  background: none;
  cursor: pointer;
}

.po-list {
  position: absolute;
  margin-top: 32px;
  display: inline-block;
  white-space: nowrap;
  z-index: 20;
  width: auto;
  box-shadow: 0 2px 5px -1px #677274;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.po-list-justify-right {
  right: 0;
}

.po-list-above {
  top: unset;
  bottom: 34px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL1BvcG92ZXIiLCJzb3VyY2VzIjpbInBvcG92ZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFLQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0VBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
