import './exportDropdown.scss';

import { Menu, MenuItem, MenuItemOnClickFunction } from '../Menu';
import { MenuList } from '../MenuList';
import React, { useContext, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  TAB,
  ERROR_MESSAGE_TEXT,
  EXPORT_SUCCESS_MESSAGE,
  ALL_SCHOOLS_ITEM,
  ALL_SCHOOLS_EXPORT_ITEM,
  EXPORT_BUTTON_STATIC_TEXT,
  EXPORT_CHOICE,
  StickyColumn,
  EXPORT_LINK_EXPIRATION_SECONDS,
} from '../../constants';
import { downloadCsv } from '../../utils';
import { UserDetailsContext } from '../../App';
import { EXPORT_CHOICES_DISTRICT, EXPORT_CHOICES_SCHOOL } from '../../constants';
import { Loader, LoaderItem } from '../Loader';
import { getUserContextHighSchoolId } from '../../utils';
import { Modal } from '../Modal';

import { Toastr, ToastrItem } from '../Toastr';
import { exportAllStudents } from '../../api';
export interface ExportDropdownProps {
  isDistrictLevel?: boolean;
  numStudents?: number;
  stateName?: string;
  schoolOrCounselorAllTableColumns: StickyColumn[];
  schoolOrCounselorAllTableData: object[];
  selectedSchool?: MenuItem;
  schoolName?: string;
  selectedTab: string;
  isDisabled?: boolean;
  classYear?: string;
}

export interface ExportDropdownItem {
  headers: StickyColumn[];
  studentData: object[];
}

const exportData: ExportDropdownItem = {
  headers: [],
  studentData: [],
};

export const ExportDropdown = forwardRef((props: ExportDropdownProps, ref) => {
  const {
    isDistrictLevel,
    stateName,
    schoolOrCounselorAllTableColumns,
    schoolOrCounselorAllTableData,
    selectedSchool,
    schoolName,
    numStudents,
    selectedTab,
    isDisabled,
    classYear,
  } = props;

  const userDetails = React.useContext(UserDetailsContext);

  const [exportList, setExportList] = useState(
    isDistrictLevel
      ? [ALL_SCHOOLS_EXPORT_ITEM, ...EXPORT_CHOICES_DISTRICT]
      : [...EXPORT_CHOICES_SCHOOL]
  );

  useEffect(() => {
    const schoolIndex = exportList.findIndex(
      (item) => item.value === EXPORT_CHOICE.SELECTED_SCHOOL
    );
    const counselorIndex = exportList.findIndex(
      (item) => item.value === EXPORT_CHOICE.SELECTED_COUNSELOR
    );
    const currentIndex = exportList.findIndex((item) => item.value === EXPORT_CHOICE.CURRENT_VIEW);

    if (selectedSchool?.value === ALL_SCHOOLS_ITEM.value) {
      exportList[schoolIndex].disabled = true;
      exportList[currentIndex].disabled = true;
    } else if (selectedTab === TAB.STUDENT_DETAILS) {
      isDistrictLevel
        ? (exportList[schoolIndex].disabled = false)
        : (exportList[counselorIndex].disabled = false);
      exportList[currentIndex].disabled = false;
    } else {
      isDistrictLevel
        ? (exportList[schoolIndex].disabled = false)
        : (exportList[counselorIndex].disabled = false);
      exportList[currentIndex].disabled = true;
    }

    setExportList([...exportList]);
  }, [selectedSchool, selectedTab]);

  const storeExportData = (headers: StickyColumn[], studentData: object[]) => {
    exportData.headers = headers;
    exportData.studentData = studentData;
  };
  useImperativeHandle(ref, () => ({ storeExportData }));
  const [loader, setLoader] = useState<LoaderItem>({
    size: 'medium',
    loading: false,
  });
  const [isExportAllVisible, setExportAllVisible] = useState(false);

  const [toastr, setToastr] = useState<ToastrItem>({
    type: 'success',
    show: false,
  });

  const getExportChoice: MenuItemOnClickFunction<MenuItem> = (selected: MenuItem) => {
    const filename = `${stateName}_SRI_Export`;

    if (selected?.value === EXPORT_CHOICE.CURRENT_VIEW) {
      downloadCsv(exportData.headers, exportData.studentData, filename, showLoader, showToastr);
      showLoader({ loading: true });
    } else if (selected?.value === EXPORT_CHOICE.SELECTED_SCHOOL) {
      const allTableData = schoolOrCounselorAllTableData.map((rowData) => {
        return {
          ...rowData,
          schoolName: selectedSchool?.value,
          schoolId: selectedSchool?.key,
        };
      });
      downloadCsv(schoolOrCounselorAllTableColumns, allTableData, filename, showLoader, showToastr);
    } else if (selected?.value === EXPORT_CHOICE.SELECTED_COUNSELOR) {
      const allTableData = schoolOrCounselorAllTableData.map((rowData) => {
        return {
          ...rowData,
          schoolName: schoolName,
          schoolId: getUserContextHighSchoolId(),
        };
      });
      downloadCsv(schoolOrCounselorAllTableColumns, allTableData, filename, showLoader, showToastr);
    } else if (selected?.value === EXPORT_CHOICE.ALL_SCHOOLS) {
      toggleExportAllVisible();
    }
  };

  const { canExportData } = useContext(UserDetailsContext);
  const showLoader = (loaderOps: LoaderItem) => {
    setLoader({
      ...loader,
      ...loaderOps,
    });
  };

  const toggleExportAllVisible = () => {
    setExportAllVisible(!isExportAllVisible);
  };

  const exportAllModelCancel = () => {
    toggleExportAllVisible();
  };
  const exportAllModelExport = async () => {
    toggleExportAllVisible();
    try {
      exportAllStudents(classYear);
      showLoader({ loading: true });
      showToastr({ show: false });
      setTimeout(() => {
        showLoader({ loading: false });
        showToastr({
          type: 'success',
          text: `<strong>Export in progress</strong><BR> A link will be emailed when the .CSV file is ready to be downloaded. For security purposes, the link will expire in ${
            EXPORT_LINK_EXPIRATION_SECONDS / 3600
          } hours.`,
          show: true,
        });
      }, 100);
    } catch (e) {
      showToastr({
        type: 'error',
        text: '<strong>Export failed</strong><BR> Unable to export at this time. Please try again later.',
        show: true,
      });
    }
  };
  const showToastr = (toastrOps: ToastrItem) => {
    toastr.text =
      toastrOps.type === 'error'
        ? ERROR_MESSAGE_TEXT
        : `<strong>Export Complete</strong><BR> ${EXPORT_SUCCESS_MESSAGE}`;
    setToastr({
      ...toastr,
      ...toastrOps,
    });
  };

  return (
    <>
      {toastr.show && <Toastr type={toastr.type} text={toastr.text} isClose={true} />}
      {loader.loading && <Loader size={loader.size} timeout={loader.timeout} />}
      <div className="export-dropdown">
        <Menu
          withDivider
          size="x-small"
          color="blue"
          placeholder={'Export'}
          menuItemOnClick={getExportChoice}
          iconColor={'blue'}
          placeHolderColor="blue"
          disabled={!canExportData || isDisabled}
        >
          <MenuList
            list={exportList}
            staticTextHeader={EXPORT_BUTTON_STATIC_TEXT}
            itemClassName="dd-item-list-export-dropdown"
            justifyRight
          />
        </Menu>
        <Modal isVisible={isExportAllVisible} toggleVisibility={toggleExportAllVisible}>
          <div className="export-all-modal__title">
            Export Student Data for All Schools and Years?
          </div>

          <div className="export-all-modal__content">
            <div>
              Data for <b>{numStudents ? numStudents.toLocaleString() : 0}</b> students will be
              exported.
            </div>

            <div>
              A link will be emailed to {userDetails.email} when the .CSV file is ready for
              download. For security reasons, the link will expire in 2 hours.
            </div>
          </div>

          <div className="export-all-modal__actions">
            <button
              type="button"
              id="cancel-export-button"
              className="outline-button"
              onClick={exportAllModelCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              id="confirm-export-button"
              className="filled-button"
              onClick={exportAllModelExport}
            >
              Export
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
});

ExportDropdown.displayName = 'ExportDropdown';
