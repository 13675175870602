import { apiClient } from '../utils';
import { GET_DISTRICT_ENDPOINT, GET_SCHOOL_ENDPOINT } from '../constants';
import { StudentMessage } from '../../../interfaces/api';

export const getDistrictStudentMessage = async (districtId: string) => {
  const url = `${GET_DISTRICT_ENDPOINT}/${districtId}/studentmessage`;

  try {
    const message = await apiClient({ url, method: 'GET' });
    return message;
  } catch (err) {
    return { message: '' };
  }
};

export const postDistrictStudentMessage = async (districtId: string, message: string) => {
  const data: StudentMessage = {
    message,
  };
  const url = `${GET_DISTRICT_ENDPOINT}/${districtId}/studentmessage`;
  await apiClient({ url, method: 'POST', data });
};

export const getSchoolStudentMessage = async (schoolId: string) => {
  const url = `${GET_SCHOOL_ENDPOINT}/${schoolId}/studentmessage`;

  try {
    const message = await apiClient({ url, method: 'GET' });
    return message;
  } catch (err) {
    return { message: '' };
  }
};

export const postSchoolStudentMessage = async (schoolId: string, message: string) => {
  const data: StudentMessage = {
    message,
  };
  const url = `${GET_SCHOOL_ENDPOINT}/${schoolId}/studentmessage`;
  await apiClient({ url, method: 'POST', data });
};
