import React, { SetStateAction, useEffect, useState, useContext } from 'react';

import './card.scss';

import { CardBanner } from '../../components/CardBanner';
import { MenuItem } from '../../components/Menu';
import { Footer } from '../../components/Footer';
import { Overview } from '../../components/Overview';
import { StudentDetails } from '../../components/StudentDetails';
import { IconType, Notice, NOTICE_TYPE } from '../../components/Notice';
import {
  ALL_SCHOOLS_ITEM,
  StickyColumn,
  STUDENT_TABLE_DEFAULT_COLUMNS,
  STUDENT_TABLE_DISTRICT_COLUMNS,
  STUDENT_TABLE_EXPORT_COLUMNS,
  STUDENT_TABLE_SCHOOL_COLUMNS,
  TAB,
} from '../../constants';
import {
  Indicator,
  getOverviewData,
  getIndicators,
  studentTableIndicatorColumns,
  getStudentDetails,
} from '../../api';
import { Student } from '../../../../interfaces/api';
import {
  getUserContextDistrictId,
  getUserContextHighSchoolId,
  pushState,
  addTabNavFunction,
  setLocalStorageValueForKeyMonthDayYear,
  isLocalStorageValueSetForKeyMonthDayYear,
} from '../../utils';
import { LoaderItem } from '../../components/Loader';
import { ToastrItem } from '../../components/Toastr';
import { Icon } from '../../components/Icon';

import { getUserContextHsDistrictId } from '../../utils';
import { UserDetailsContext } from '../../App';
import { DISTRICT_DATA_IMPORT, SCHOOL_DATA_IMPORT } from '../../constants';

export interface Props {
  isDistrictLevel: boolean;
  selectedSchool?: MenuItem;
  selectedCounselor?: MenuItem;
  selectedClassYear?: MenuItem;
  schools?: MenuItem[];
  counselors?: MenuItem[];
  showLoader: (loading: LoaderItem) => void;
  showToastr: (toastr: ToastrItem) => void;
  hasStudentReadinessData?: boolean;
  hasImportedData?: boolean;
  notProvisioned: boolean;
  gotoManagePage: () => void;
  showManageButton: boolean;
  getExportData?: (
    stateName: string,
    allExportTableColumns: StickyColumn[],
    allExportTableData: object[]
  ) => void;
  getFilteredExportTableData?: (
    filteredTableExportData: object[],
    tableCurrentColumns: StickyColumn[]
  ) => void;
  updateDistrictStudentsCount?: (studentsCount: number) => void;
  onTabSelected: React.Dispatch<SetStateAction<string>>;
  loaderState?: boolean;
  updateHasStudentReadinessData?: () => void;
  loadRulesByClassYear?: boolean;
}

export interface BarGraphDataItem {
  id: string;
  name: string;
  value: number;
}

const tabs = [TAB.OVERVIEW, TAB.STUDENT_DETAILS];
let previousSelectedClassYear: string | undefined = '0';

export const Card = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (Card as React.FC).displayName = 'Card';
  const {
    isDistrictLevel,
    selectedSchool,
    selectedCounselor,
    selectedClassYear,
    schools,
    counselors,
    showLoader,
    showToastr,
    hasStudentReadinessData,
    hasImportedData,
    notProvisioned,
    gotoManagePage,
    showManageButton,
    getExportData,
    getFilteredExportTableData,
    onTabSelected,
    updateDistrictStudentsCount,
    loaderState,
    updateHasStudentReadinessData,
    loadRulesByClassYear = false,
  } = props;
  const emptyIndicators: Indicator[] = [];

  const [{ indicators, indicatorsMap }, setIndicators] = useState<{
    indicators: Indicator[];
    indicatorsMap: { [key: string]: Indicator };
  }>({
    indicators: emptyIndicators,
    indicatorsMap: {},
  });
  const [indicator, setIndicator] = useState<MenuItem>(indicators[0]);
  const [tab, setSelectedTab] = useState<string>(tabs[0]);
  const [overallPercentage, setOverallPercentage] = useState<number>(0);
  const [schoolsOrCounselorsGraphData, setSchoolsOrCounselorsGraphData] = useState<
    BarGraphDataItem[]
  >([]);
  const [indicatorsGraphData, setIndicatorsGraphData] = useState<BarGraphDataItem[]>([]);
  const [schoolsOrCounselorsGraphTitle, setSchoolsOrCounselorsGraphTitle] = useState<string>('');
  const [indicatorsGraphTitle, setIndicatorsGraphTitle] = useState<string>('');
  // For student table
  const [schoolOrCounselorTableColumns, setSchoolOrCounselorTableColumns] = useState<
    StickyColumn[]
  >([]);
  const [schoolOrCounselorTableData, setSchoolOrCounselorTableData] = useState<object[]>([]);
  const [studentDetailsRetrieved, setStudentDetailsRetrieved] = useState(false); // loader state
  const [overviewDataRetrieved, setOverviewDataRetrieved] = useState(false); // loader state
  const [stateName, setStateName] = useState<string>('');
  const [showImportBanner, setShowImportBanner] = useState(true);

  const isSchoolInDistrict = getUserContextHsDistrictId() !== undefined;

  const setStudentDetailsTabData = async () => {
    let allExportTableData: object[] = [];
    if (selectedClassYear && (!isDistrictLevel || selectedSchool?.key !== '0')) {
      showLoader({ loading: true });
      const studentDetails = await getStudentDetails(
        isDistrictLevel ? getUserContextDistrictId() : null,
        isDistrictLevel ? (selectedSchool?.key as string) : getUserContextHighSchoolId(),
        parseInt(selectedClassYear?.key || '0')
      );
      const tableData: object[] = studentDetails.map((row: Student) => {
        return {
          ...row,
          ...{
            ['studentName']: `${row.firstName} ${row.lastName}`,
            ['counselor']: `${
              counselors?.length && row.counselorId
                ? counselors.find((counselor) => counselor.key == row.counselorId)?.value
                : ''
            }`,
          },
        };
      });
      const filteredTableData = tableData.filter((item: object) => {
        let sameCounselor = true;
        if (selectedCounselor?.key && selectedCounselor?.key !== '1') {
          sameCounselor = (item as Student).counselorId === selectedCounselor?.key;
        }
        const sameYear: boolean = `${(item as Student).classYear}` === selectedClassYear.key;
        return sameCounselor && sameYear;
      });
      allExportTableData = filteredTableData;
      setSchoolOrCounselorTableData(filteredTableData);
      if (studentDetails.length > 0) {
        setStudentDetailsRetrieved(true);
      } else {
        setStudentDetailsRetrieved(false);
      }
      showLoader({ loading: false });
    } else {
      if (isDistrictLevel && selectedSchool?.key === '0') {
        setStudentDetailsRetrieved(false);
      }
    }
    const tableColumns = [
      ...STUDENT_TABLE_DEFAULT_COLUMNS,
      ...(isDistrictLevel ? STUDENT_TABLE_DISTRICT_COLUMNS : STUDENT_TABLE_SCHOOL_COLUMNS),
      ...(studentTableIndicatorColumns(
        indicators,
        indicator?.key as string
      ) as unknown as StickyColumn[]),
    ];
    const allExportTableColumns = [
      ...STUDENT_TABLE_EXPORT_COLUMNS,
      ...(Object.values(indicators) as unknown as StickyColumn[]),
    ];
    getExportData && getExportData(stateName, allExportTableColumns, allExportTableData);
    setSchoolOrCounselorTableColumns(tableColumns);
  };

  const getFilteredTableData = (filteredTableData: object[]) => {
    getFilteredExportTableData &&
      getFilteredExportTableData(filteredTableData, schoolOrCounselorTableColumns);
  };

  const getAndParseIndicators = async () => {
    if (loadRulesByClassYear) {
      // clear Indicators so that other data does not load until we have the indicators?
      setIndicators({
        indicators: emptyIndicators,
        indicatorsMap: {},
      });
    }
    const getData = await getIndicators(selectedClassYear?.key);
    const indicatorsMap: { [key: string]: Indicator } = getData?.indicatorsMap;
    const getStateName: string = getData?.getStateName;
    const indicatorsArray: Indicator[] = Object.values(indicatorsMap);

    setIndicators({ indicators: indicatorsArray, indicatorsMap });
    setStateName(getStateName);
    const [firstIndicator] = indicatorsArray;
    if (firstIndicator) {
      setIndicator(firstIndicator as Indicator);
    }
  };

  const setOverviewTabData = async () => {
    if (indicator) {
      showLoader({ loading: true });
      const allSchools = isDistrictLevel && selectedSchool?.key === ALL_SCHOOLS_ITEM.key;
      const allCounselors = selectedCounselor?.key === '1';
      const districtSchoolId = isDistrictLevel && !allSchools ? selectedSchool?.key : null;

      const overviewData = await getOverviewData(
        indicator.key as string,
        indicators,
        parseInt(selectedClassYear?.key || '0'),
        isDistrictLevel ? getUserContextDistrictId() : null,
        schools,
        !isDistrictLevel ? getUserContextHighSchoolId() : null,
        counselors
      );

      const schoolOrCounselor = isDistrictLevel ? `School` : `Counselor`;

      const schoolOrCounselorData = isDistrictLevel
        ? overviewData.schoolsData
        : overviewData.counselorsData;

      schoolOrCounselorData.sort((a: any, b: any) =>
        a.name?.toUpperCase() < b.name?.toUpperCase() ? -1 : 1
      );

      let topLevelPercentage: number = overviewData.totalPercentageMet;
      let filteredSchoolOrCounselorData: any = schoolOrCounselorData;
      let currentSelectionsOverviewData = overviewData;
      if (isDistrictLevel && !allSchools && schoolOrCounselorData) {
        filteredSchoolOrCounselorData = schoolOrCounselorData.filter((school: any) => {
          return school.id === districtSchoolId;
        });
        topLevelPercentage = filteredSchoolOrCounselorData[0].value;
        currentSelectionsOverviewData = filteredSchoolOrCounselorData[0] as unknown as any;
      } else if (!isDistrictLevel && !allCounselors && schoolOrCounselorData) {
        filteredSchoolOrCounselorData = schoolOrCounselorData.filter((counselor: any) => {
          return counselor.id && counselor.id.toString() === selectedCounselor?.key;
        });
        topLevelPercentage = filteredSchoolOrCounselorData[0].value;
        currentSelectionsOverviewData = filteredSchoolOrCounselorData[0] as unknown as any;
      }

      // Only when all schools are selected and login with district user
      if (isDistrictLevel && updateDistrictStudentsCount) {
        updateDistrictStudentsCount(overviewData.studentsCount);
      }

      setOverallPercentage(topLevelPercentage);

      setIndicatorsGraphTitle(`Students Meeting ${indicator.value}, by Indicator`);
      setSchoolsOrCounselorsGraphTitle(
        `Students Meeting ${indicator.value}, by ${schoolOrCounselor}`
      );

      filteredSchoolOrCounselorData.sort((a: any, b: any) => {
        if (a.name === 'Unassigned') {
          return 1;
        } else if (b.name === 'Unassigned') {
          return -1;
        }

        return a.name?.toUpperCase() < b.name?.toUpperCase() ? -1 : 1;
      });

      setSchoolsOrCounselorsGraphData(filteredSchoolOrCounselorData as unknown as any);

      const overviewDataWithNames =
        Array.isArray(currentSelectionsOverviewData?.indicatorsData) &&
        currentSelectionsOverviewData?.indicatorsData?.length
          ? currentSelectionsOverviewData?.indicatorsData?.map(
              (indicatorData: BarGraphDataItem) => {
                const displayName: string = indicatorsMap[indicatorData.id].value as string;
                return { ...indicatorData, name: displayName };
              }
            )
          : [];

      setIndicatorsGraphData(overviewDataWithNames);
      setOverviewDataRetrieved(true);
      showLoader({ loading: false });
    }
  };

  if (loadRulesByClassYear) {
    /**
     * Function to reload data based on active tab
     */
    const reloadData = async () => {
      if (indicators?.length > 0) {
        switch (tab) {
          case TAB.OVERVIEW:
            if ((selectedSchool || selectedCounselor) && selectedClassYear) {
              showLoader({ loading: true });
              setOverviewDataRetrieved(false);
              setOverviewTabData();
            }
            /**
             * Retrieve student data as well for exporting
             */
            setStudentDetailsTabData();
            break;
          case TAB.STUDENT_DETAILS:
            /**
             * Show student details only on active school and active class year
             */
            if ((selectedSchool || selectedCounselor) && selectedClassYear) {
              setStudentDetailsTabData();
            }
            break;
          default:
            setStudentDetailsRetrieved(false);
            break;
        }
      }
    };

    /**
     * Load indicators on updating class year
     */
    useEffect(() => {
      if (selectedClassYear?.key) {
        getAndParseIndicators();
      }
    }, [selectedClassYear?.key]);

    /**
     * Reload data on school change, class year change (indicator) and tab change
     */
    useEffect(() => {
      reloadData();
    }, [isDistrictLevel, isDistrictLevel ? selectedSchool : selectedCounselor, indicator, tab]);
  } else {
    const reloadData = async () => {
      if (indicators?.length < 1) {
        // only retrieve indicators once
        await getAndParseIndicators();
      } else if (indicator && indicators[0]) {
        if (tab === TAB.OVERVIEW && (selectedSchool || selectedCounselor) && selectedClassYear) {
          showLoader({ loading: true });
          setOverviewDataRetrieved(false);
          setOverviewTabData();
        }
        if (
          tab === TAB.STUDENT_DETAILS &&
          (selectedSchool || selectedCounselor) &&
          selectedClassYear
        ) {
          setStudentDetailsTabData();
          if (isDistrictLevel && previousSelectedClassYear !== selectedClassYear?.key) {
            setOverviewTabData();
          }
        } else {
          setStudentDetailsRetrieved(false);
          setStudentDetailsTabData();
        }
        previousSelectedClassYear = selectedClassYear?.key;
      }
    };
    useEffect(() => {
      reloadData();
    }, [
      isDistrictLevel,
      selectedSchool,
      selectedCounselor,
      selectedClassYear,
      indicators,
      indicator,
      tab,
    ]);
  }

  const manageNoticeType = isSchoolInDistrict
    ? NOTICE_TYPE.ACTIVATE_DISTRICT_SCHOOL
    : NOTICE_TYPE.PROVISION_SCHOOL;

  const activateNoticeType = isSchoolInDistrict
    ? NOTICE_TYPE.ACTIVATE_DISTRICT_SCHOOL
    : NOTICE_TYPE.ACTIVATE_STANDALONE_SCHOOL;

  const { canImportData, contactId } = useContext(UserDetailsContext);

  const hideImportBanner = (contactId: string | number | undefined) => {
    setShowImportBanner(false);
    setLocalStorageValueForKeyMonthDayYear(`overviewImportBannerDismissed-${contactId}`);
  };

  const importBannerDismissed = (contactId: string | number | undefined) => {
    return isLocalStorageValueSetForKeyMonthDayYear(`overviewImportBannerDismissed-${contactId}`);
  };

  const importDistrictNotice = canImportData
    ? NOTICE_TYPE.IMPORT_DISTRICT
    : NOTICE_TYPE.IMPORT_DISTRICT_NO_PERMISSION;
  const importSchoolNotice = canImportData
    ? NOTICE_TYPE.IMPORT_SCHOOL
    : NOTICE_TYPE.IMPORT_SCHOOL_NO_PERMISSION;
  const importBannerLink = isDistrictLevel ? DISTRICT_DATA_IMPORT : SCHOOL_DATA_IMPORT;
  const importBannerLinkOrText = canImportData ? (
    <a href={importBannerLink}>Data Import New</a>
  ) : (
    'Data Import New'
  );

  // wrap tab select functions and use this new function so that hash routing works
  const selectHashTab = (tab: string, push = true) => {
    const hashTab = tab.toLowerCase().replace(' ', '');
    push ? pushState(hashTab) : () => true;
    setSelectedTab(tab);
    onTabSelected(tab);
  };

  // add the tab navigation functions for onhashchange to use
  addTabNavFunction('overview', selectHashTab, TAB.OVERVIEW, false);
  addTabNavFunction('studentdetails', selectHashTab, TAB.STUDENT_DETAILS, false);

  // sync the current tab and hash
  const currentHash = window.location.hash.replace('#', '');
  if (
    ['overview', 'studentdetails'].includes(currentHash) &&
    tab.toLowerCase().replace(' ', '') !== currentHash
  ) {
    const syncTab = currentHash === TAB.OVERVIEW.toLowerCase() ? TAB.OVERVIEW : TAB.STUDENT_DETAILS;
    setTimeout(() => {
      selectHashTab(syncTab, false);
    }, 100);
  }

  return (
    <div className="card-wrapper">
      {((indicators.length > 0 && indicator) || loadRulesByClassYear) && (
        <div className="card">
          {indicators.length > 0 && indicator && (
            <CardBanner
              indicators={indicators as MenuItem[]}
              tabs={tabs}
              setIndicator={setIndicator}
              setSelectedTab={selectHashTab as any}
              defaultTab={tab}
            />
          )}
          <div className="card-content">
            <div className="indicator-name">{indicator?.value || ''}</div>
            {notProvisioned ? (
              <>
                {showManageButton ? (
                  <>
                    <Notice
                      noticeType={
                        isDistrictLevel ? NOTICE_TYPE.PROVISION_DISTRICT : manageNoticeType
                      }
                    />
                    {(isDistrictLevel || !isSchoolInDistrict) && (
                      <button type="button" className="manage-button" onClick={gotoManagePage}>
                        <Icon name="gear" />
                        <div className="manage-button-text">Manage</div>
                      </button>
                    )}
                  </>
                ) : (
                  <Notice
                    noticeType={
                      isDistrictLevel ? NOTICE_TYPE.ACTIVATE_DISTRICT_SCHOOL : activateNoticeType
                    }
                  />
                )}
              </>
            ) : (
              <>
                {tab === TAB.OVERVIEW ? (
                  <>
                    {overviewDataRetrieved &&
                    hasStudentReadinessData &&
                    selectedClassYear?.key !== '0' ? (
                      <>
                        {!hasImportedData && !importBannerDismissed(contactId) && showImportBanner && (
                          <div className="sri-please-import-banner">
                            <div className="sri-please-import-banner-text">
                              <strong>Import Student Readiness Indicators Data</strong> The data on
                              the Overview page was manually entered for students. Prepare your data
                              import file using the SRI import template on the{' '}
                              {importBannerLinkOrText} page.
                            </div>
                            <button
                              className="sri-please-import-banner-close"
                              onClick={() => hideImportBanner(contactId)}
                            >
                              <Icon name="close" size={16} />
                            </button>
                          </div>
                        )}
                        <Overview
                          isDistrictLevel={isDistrictLevel}
                          indicator={indicator}
                          selectedSchool={selectedSchool}
                          selectedCounselor={selectedCounselor}
                          selectedClassYear={selectedClassYear}
                          schoolsOrCounselorsGraphData={schoolsOrCounselorsGraphData}
                          schoolsOrCounselorsGraphTitle={schoolsOrCounselorsGraphTitle}
                          indicatorsGraphData={indicatorsGraphData}
                          indicatorsGraphTitle={indicatorsGraphTitle}
                          overallPercentageMet={overallPercentage}
                        />
                      </>
                    ) : (
                      <>
                        {overviewDataRetrieved && !loaderState && (
                          <Notice
                            icon={IconType.STUDENT}
                            noticeType={isDistrictLevel ? importDistrictNotice : importSchoolNotice}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {tab === TAB.STUDENT_DETAILS && (
                      <>
                        {studentDetailsRetrieved ? (
                          <StudentDetails
                            isDistrictLevel={isDistrictLevel}
                            schoolOrCounselorTableColumns={schoolOrCounselorTableColumns}
                            schoolOrCounselorTableData={schoolOrCounselorTableData}
                            counselors={counselors}
                            indicatorKey={indicator.key}
                            indicators={indicators}
                            indicatorsMap={indicatorsMap}
                            selectedSchool={selectedSchool}
                            showToastr={showToastr}
                            showLoader={showLoader}
                            getFilteredTableData={getFilteredTableData}
                            updateHasStudentReadinessData={updateHasStudentReadinessData}
                            hasStudentReadinessData={hasStudentReadinessData}
                          />
                        ) : (
                          <>
                            {!loaderState && (
                              <Notice
                                icon={
                                  isDistrictLevel && selectedSchool?.key === '0'
                                    ? IconType.SCHOOL
                                    : IconType.STUDENT
                                }
                                noticeType={
                                  isDistrictLevel && selectedSchool?.key === '0'
                                    ? NOTICE_TYPE.SELECT_SCHOOL
                                    : importSchoolNotice
                                }
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
