import './tabs.scss';
import React, { useState, MouseEvent, Dispatch, SetStateAction, useEffect } from 'react';

export interface Props {
  tabs: string[];
  setSelectedTab: Dispatch<SetStateAction<string>>;
  defaultTab?: string;
}

export const Tabs = (props: Props): JSX.Element => {
  (Tabs as React.FC).displayName = 'Tabs';
  const { tabs, setSelectedTab, defaultTab } = props;
  const [activeTab, activateTab] = useState(tabs[0]);

  const setActiveTab = (e: MouseEvent) => {
    e.stopPropagation();
    const target = e.currentTarget as HTMLElement;
    const newTab = target.getAttribute('data-tab');
    if (newTab && newTab !== activeTab) {
      activateTab(newTab);
      setSelectedTab(newTab);
    }
  };

  if (defaultTab && defaultTab !== activeTab) {
    activateTab(defaultTab);
  }

  return (
    <div className="tabs">
      {tabs.map((tab: string) => (
        <button
          type="button"
          className={tab === activeTab ? 'tab tab-active' : 'tab'}
          key={tab}
          data-tab={tab}
          onClick={(e) => setActiveTab(e)}
          data-testid={tab === activeTab ? 'active-tab' : 'inactive-tab'}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};
